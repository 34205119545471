import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_URL}/api`,
});

// Add an interceptor to handle 401 (Unauthorized) response
client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Redirect to the unauthorized page
      const allowedPaths = ["/unauthorized"];

      const currentPath = window.location.pathname;

      if (!allowedPaths.some((path) => currentPath.startsWith(path))) {
        window.location.href = "/unauthorized"; // Replace with the actual path of the unauthorized page
      }
    }
    return Promise.reject(error);
  }
);

// Add an interceptor to include the authentication token in the request headers
client.interceptors.request.use(async (config) => {
  // Dynamically get the latest authToken before each request
  const authToken = await getAuthToken();
  if (authToken) {
    config.headers["authorization"] = `${authToken}`;
  }
  return config;
});

// Function to get the latest authToken
const getAuthToken = async () => {
  return new Promise((resolve) => {
    const authToken = localStorage.getItem("authToken");
    resolve(authToken);
  });
};

export default client;
