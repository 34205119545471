import React, { useState, useEffect } from "react";
import { addTemplates } from "../../../api/whatsapp";
import { toast } from "react-hot-toast";
import { Spin } from "antd";

const Templates = () => {
  const [loading, setLoading] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [formData, setFormData] = useState({
    accountname: "",
    source: "",
    tempName: "",
    lang: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // Update account options based on the selected source
    if (formData.source === "omni") {
      setAccountOptions(["acc1"]);
    } else if (formData.source === "netcore") {
      setAccountOptions(["egniolsix_wa", "egniolfive_wa", "egnioldigitalwaba"]);
    } else if (formData.source === "test") {
      setAccountOptions(["acc2"]);
    } else {
      setAccountOptions(["Please select source first"]);
    }
  }, [formData.source]);

  const addTemplateData = async (event) => {
    event.preventDefault();
    setLoading(true);

    const templateUpload = await addTemplates(formData);
    if (templateUpload) {
      toast.success("Template inserted successfully");
      setFormData({
        accountname: "",
        source: "",
        tempName: "",
        lang: "",
      });
    } else {
      toast.error("Error inserting data");
    }
    setLoading(false);
  };

  let inputClassName =
    "px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500 w-full bg-gray-100";

  return (
    <div>
      <div className="bg-white p-8 rounded-lg max-h-4/5 mx-auto border-2 border-gray-300">
        <form>
          <div className="grid grid-cols-2 gap-4 ">
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Source
              </label>
              <select
                name="source"
                value={formData.source}
                onChange={(e) => handleChange(e)}
                className="input-field border p-2 border-cyan-700 rounded-md w-full"
                required
              >
                <option value="" disabled>
                  Select source
                </option>
                <option>netcore</option>
                <option>omni</option>
                <option>test</option>
              </select>
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Account
              </label>
              <select
                name="accountname"
                value={formData.accountname}
                onChange={(e) => handleChange(e)}
                className="input-field border p-2 border-cyan-700 rounded-md w-full"
                required
              >
                <option value="" disabled>
                  Select Account
                </option>
                {accountOptions.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Template Name
              </label>
              <input
                type="text"
                name="tempName"
                id="tempName"
                className={inputClassName}
                value={formData.tempName}
                onChange={(e) => handleChange(e)}
                required
              />
            </div>

            <div className="mt-4">
              <label className="block text-sm font-medium text-gray-700">
                Language
              </label>
              <select
                name="lang"
                value={formData.lang}
                onChange={(e) => handleChange(e)}
                className="input-field border p-2 border-cyan-700 rounded-md w-full"
                required
              >
                <option value="" disabled>
                  Select Language
                </option>
                <option value="ta">Tamil</option>
                <option value="hi">Hindi</option>
                <option value="en">English</option>
              </select>
            </div>

            <div className="flex justify-end mt-5">
              <button
                onClick={addTemplateData}
                className="text-white bg-blue-500 px-2 py-1 text-sm rounded-lg"
              >
                {loading ? <Spin size="small" /> : "Add"}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Templates;
