import React, { useEffect, useState, useRef } from "react";

import { Table, Input, Button, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";

import { getCompanyDetails } from "../../api/airtel";

const EmployeeDetails = ({
  mobileNumber,
  employeeClassWiseCallsData,
  employeeCallsData,
  day,
  month,
  selectedClass,
  employeeName,
  employeeClientData,
}) => {
  const filteredEmployeeClientData = employeeClientData.filter(
    (data) =>
      data.Employee_Number === mobileNumber.replace(/^0+/, "").slice(-10)
  );

  // const [clientCollection, setClientCollection] = useState([]);
  // setClientCollection(filteredEmployeeClientData);

  const [companyDetails, setCompanyDetails] = useState([]);
  const [loading, setLoading] = useState([]);

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Customer Number",
      dataIndex: "Customer_Number",
      key: "Customer_Number",
      width: 200,
      ...getColumnSearchProps("Customer_Number"),
    },
    {
      title: "Company Name",
      dataIndex: "companyName",
      key: "companyName",
      ...getColumnSearchProps("companyName"),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Frequency",
      dataIndex: "callFrequency",
      key: "callFrequency",
      // sorter: (a, b) => a.callFrequency - b.callFrequency,
    },
    {
      title: "Total Conversation Duration (Minute)",
      dataIndex: "totalCallDuration",
      key: "totalCallDuration",
      sorter: (a, b) => a.totalCallDuration - b.totalCallDuration,
    },
    {
      title: "Collection",
      sorter: (a, b) => {
        const aCollection =
          filteredEmployeeClientData.find(
            (data) => data.COMPANY_NAME === a.companyName
          )?.Total_Received_Amount || 0;
        const bCollection =
          filteredEmployeeClientData.find(
            (data) => data.COMPANY_NAME === b.companyName
          )?.Total_Received_Amount || 0;
        return aCollection - bCollection;
      },
      render: (_, record) => {
        const companyInFilteredData = filteredEmployeeClientData.find(
          (data) => data.COMPANY_NAME === record.companyName
        );
        return companyInFilteredData
          ? companyInFilteredData.Total_Received_Amount
          : 0;
      },
    },
  ];

  // To get the Callfrequency and call duration from the array
  const serachDetailsinArray = (arr, customerNumber) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].customerNumber === "0" + customerNumber) {
        return arr[i];
      }
    }
    return null;
  };

  const fetchData = async () => {
    setLoading(true);
    let mobileNumbers = [];
    // Extract all customer numbers
    employeeClassWiseCallsData[mobileNumber][selectedClass].Customers.forEach(
      (customer) => {
        mobileNumbers.push(customer.customerNumber);
      }
    );
    const data = await getCompanyDetails({
      agentNumber: mobileNumber,
      mobileNumbers,
    });
    if (data.success) {
      let companies = data.result;
      let mergedData = [];
      Object.keys(companies).forEach((companyNumber) => {
        const { callFrequency, totalCallDuration } = serachDetailsinArray(
          employeeClassWiseCallsData[mobileNumber][selectedClass].Customers,
          companyNumber
        );
        mergedData.push({
          Customer_Number: companyNumber,
          companyName: companies[companyNumber].companyName,
          source: companies[companyNumber].source,
          callFrequency,
          totalCallDuration: totalCallDuration.toFixed(2),
        });
      });

      setCompanyDetails(mergedData);
    } else {
      setCompanyDetails([]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, [mobileNumber, selectedClass, day, month]);

  return (
    <div>
      <h1 className="font-semibold text-xl text-center my-4">
        {!employeeName ? "UNKNOWN AGENT" : employeeName.toUpperCase()}
      </h1>

      {loading ? (
        <Spin />
      ) : (
        <Table columns={columns} dataSource={companyDetails} />
      )}
    </div>
  );
};

export default EmployeeDetails;
