import React, { useState } from "react";
import toast from "react-hot-toast";
import {
  ComplainForm,
  ComplainFormCSV,
  DtmfCSV,
  ScrapCSV,
  FacebookCSVLIGMR,
  FacebookCSVEGNIOL,
  EgniolLiveData,
  searchNumber,
} from "../../api/complainForm";
import Papa from "papaparse";
import { Tooltip, Select } from "antd";
import { FaDownload } from "react-icons/fa6";

import csvSample from "../../csv/complainForm_sample.csv";
import csvSample1 from "../../csv/dtmf_sample.csv";
import csvSample2 from "../../csv/Sample_scrap.csv";
import csvSample3 from "../../csv/Sample_Facebook.csv";
import csvSample4 from "../../csv/Sample_Facebook.csv";

const Form = () => {
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchNumberDetails, setSearchNumberDetails] = useState({});
  const [formData, setFormData] = useState({
    category: "",
    mobileNo: "",
    email: "",
    companyName: "",
    other: "",
  });

  const [csvData, setCSVData] = useState([]);
  const [csvDtmfData, setCSVDtmfData] = useState([]);
  const [csvScrapData, setCSVScrapData] = useState([]);
  const [csvFacebookDataLIGMR, setCSVFacebookDataLIGMR] = useState([]);
  const [csvFacebookDataEGNIOL, setCSVFacebookDataEGNIOL] = useState([]);
  const [csvPTDataEGNIOL, setCSVPTDataEGNIOL] = useState([]);

  const [rowCount, setRowCount] = useState(null);
  const [rowDtmfCount, setRowDtmfCount] = useState(null);
  const [rowFacebookCountLIGMR, setRowFacebookCountLIGMR] = useState(null);
  const [rowFacebookCountEGNIOL, setRowFacebookCountEGNIOL] = useState(null);
  const [rowPTCountEGNIOL, setRowPTCountEGNIOL] = useState(null);

  const [isFieldFilled, setIsFieldFilled] = useState(false);

  const [lodder, setLodder] = useState(false);
  const [lodderDtmf, setLodderDtmf] = useState(false);
  const [lodderScrap, setLodderScarp] = useState(false);
  const [lodderFacebookLIGMR, setLodderFacecbookLIGMR] = useState(false);
  const [lodderFacebookEGNIOL, setLodderFacecbookEGNIOL] = useState(false);
  const [lodderPTEGNIOL, setLodderPTEGNIOL] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    // Check if at least one field is filled
    setIsFieldFilled(
      Object.values(formData).some((field) => field.trim().length > 0)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if at least one field is filled
    if (!isFieldFilled) {
      toast.error("At least one field is required");
      return; // Stop further execution
    }

    try {
      const data = await ComplainForm(formData);
      if (data.success) {
        toast.success(data.message);
        setFormData({
          category: "",
          mobileNo: "",
          email: "",
          companyName: "",
          other: "",
        });
      }
    } catch (error) {
      console.log("error :", error);
      toast.error("error:", error);
    }
  };

  const handleCSVChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const modifiedData = result.data.map((entry) => {
          // Check if Mobile property exists and is a string
          if (typeof entry.Mobile === "string") {
            // Remove all spaces from the mobile number and take the last 10 digits
            const trimmedMobileNo = entry.Mobile.replace(/\s/g, "").slice(-10);
            // Return the modified entry
            return {
              ...entry,
              Mobile: trimmedMobileNo,
            };
          }
          // Return the entry without modification if Mobile is not a string or undefined
          return entry;
        });
        setCSVData(modifiedData);
      },
      error: (error) => {
        console.error("Error:", error);
        toast.error("Error parsing CSV file");
      },
    });
  };

  const handleCSVDtmfChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const modifiedData = result.data.map((entry) => {
          // Check if Mobile property exists and is a string
          if (typeof entry.phone_numbers === "string") {
            // Remove all spaces from the mobile number and take the last 10 digits
            const trimmedMobileNo = entry.phone_numbers
              .replace(/\s/g, "")
              .slice(-10);
            // Return the modified entry
            return {
              ...entry,
              phone_numbers: trimmedMobileNo,
            };
          }
          // Return the entry without modification if Mobile is not a string or undefined
          return entry;
        });
        setCSVDtmfData(modifiedData);
      },
      error: (error) => {
        console.error("Error:", error);
        toast.error("Error parsing CSV file");
      },
    });
  };
  const handleCSVScrapChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const modifiedData = result.data.map((entry) => {
          // Check if Mobile property exists and is a string
          if (typeof entry.phone_numbers === "string") {
            // Remove all spaces from the mobile number and take the last 10 digits
            const trimmedMobileNo = entry.phone_numbers
              .replace(/\s/g, "")
              .slice(-10);
            // Return the modified entry
            return {
              ...entry,
              phone_numbers: trimmedMobileNo,
            };
          }
          // Return the entry without modification if Mobile is not a string or undefined
          return entry;
        });
        setCSVScrapData(modifiedData);
      },
      error: (error) => {
        console.error("Error:", error);
        toast.error("Error parsing CSV file");
      },
    });
  };
  const handleCSVFacebookChangeLIGMR = (e) => {
    // const file = e.target.files[0];
    // if (!file) return;
    const files = e.target.files;
    
    for (const file of files) {

      if (!file) return;


    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const modifiedData = result.data.map((entry) => {
          // Check if Mobile property exists and is a string
          if (typeof entry.phone_numbers === "string") {
            // Remove all spaces from the mobile number and take the last 10 digits
            const trimmedMobileNo = entry.phone_numbers
              .replace(/\s/g, "")
              .slice(-10);
            // Return the modified entry
            return {
              ...entry,
              phone_numbers: trimmedMobileNo,
            };
          }
          // Return the entry without modification if Mobile is not a string or undefined
          return entry;
        });
        // setCSVFacebookDataLIGMR(modifiedData);
        setCSVFacebookDataLIGMR((prevData) => [...prevData, ...modifiedData])
      },
      error: (error) => {
        console.error("Error:", error);
        toast.error("Error parsing CSV file");
      },
    });
    }
  };
  const handleCSVFacebookChangeEGNIOL = (e) => {
    // const file = e.target.files[0];
    const files = e.target.files;
    
    for (const file of files) {
    
      if (!file) return;

      Papa.parse(file, {
        header: true,
        complete: (result) => {
          const modifiedData = result.data.map((entry) => {
            // Check if Mobile property exists and is a string
            if (typeof entry.phone_numbers === "string") {
              // Remove all spaces from the mobile number and take the last 10 digits
              const trimmedMobileNo = entry.phone_numbers
                .replace(/\s/g, "")
                .slice(-10);
              // Return the modified entry
              return {
                ...entry,
                phone_numbers: trimmedMobileNo,
              };
            }
            // Return the entry without modification if Mobile is not a string or undefined
            return entry;
          });
          // setCSVFacebookDataEGNIOL(modifiedData);
          // Update the state for each file separately, accumulating data
          setCSVFacebookDataEGNIOL((prevData) => [...prevData, ...modifiedData])
        },
        error: (error) => {
          console.error("Error:", error);
          toast.error("Error parsing CSV file");
        },
      });
    }
  };


  const handleCSVPTChangeEGNIOL = (e) => {
    // const file = e.target.files[0];
    // if (!file) return;
    const files = e.target.files;
    
    for (const file of files) {

      if (!file) return;


    Papa.parse(file, {
      header: true,
      complete: (result) => {
        const modifiedData = result.data.map((entry) => {
          // Check if Mobile property exists and is a string
          if (typeof entry.phone_numbers === "string") {
            // Remove all spaces from the mobile number and take the last 10 digits
            const trimmedMobileNo = entry.phone_numbers
              .replace(/\s/g, "")
              .slice(-10);
            // Return the modified entry
            return {
              ...entry,
              phone_numbers: trimmedMobileNo,
            };
          }
          // Return the entry without modification if Mobile is not a string or undefined
          return entry;
        });
        // setCSVFacebookDataLIGMR(modifiedData);
        setCSVPTDataEGNIOL((prevData) => [...prevData, ...modifiedData])
      },
      error: (error) => {
        console.error("Error:", error);
        toast.error("Error parsing CSV file");
      },
    });
    }
  };

  const handleCSVSubmit = async () => {
    setLodder(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await ComplainFormCSV(csvData);
      if (data.success) {
        toast.success("CSV data successfully processed");
        setRowCount(data.rowCount);
        setCSVData([]); // Clear the CSV data after processing
        setLodder(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodder(false); // Reset loader to false after CSV form submission is complete
    }
  };

  const handleCSVDtmfSubmit = async () => {
    setLodderDtmf(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await DtmfCSV(csvDtmfData);
      console.log(data, "enee");
      if (data.success) {
        toast.success("CSV data successfully processed");
        setRowDtmfCount(data.rowCount);
        setCSVDtmfData([]); // Clear the CSV data after processing
        setLodderDtmf(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodderDtmf(false); // Reset loader to false after CSV form submission is complete
    }
  };

  const handleCSVScrapSubmit = async () => {
    setLodderScarp(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await ScrapCSV(csvScrapData);
      if (data.success) {
        toast.success("Scrap CSV data successfully processed");
        setRowDtmfCount(data.rowCount);
        setCSVDtmfData([]); // Clear the CSV data after processing
        setLodderScarp(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodderScarp(false); // Reset loader to false after CSV form submission is complete
    }
  };

  const handleCSVFacebookSubmitLIGMR = async () => {
    setLodderFacecbookLIGMR(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await FacebookCSVLIGMR(csvFacebookDataLIGMR);
      if (data.success) {
        toast.success("Scrap CSV data successfully processed");
        setRowFacebookCountLIGMR(data.rowCount);
        setCSVFacebookDataLIGMR([]); // Clear the CSV data after processing
        setLodderFacecbookLIGMR(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodderFacecbookLIGMR(false); // Reset loader to false after CSV form submission is complete
    }
  };
  const handleCSVFacebookSubmitEGNIOL = async () => {
    setLodderFacecbookEGNIOL(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await FacebookCSVEGNIOL(csvFacebookDataEGNIOL);
      if (data.success) {
        toast.success("Scrap CSV data successfully processed");
        setRowFacebookCountEGNIOL(data.rowCount);
        setCSVFacebookDataEGNIOL([]); // Clear the CSV data after processing
        setLodderFacecbookEGNIOL(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodderFacecbookEGNIOL(false); // Reset loader to false after CSV form submission is complete
    }
  };
  const handleCSVPTSubmitEGNIOL = async () => {
    setLodderPTEGNIOL(true);
    try {
      // Here you can implement your logic to store the CSV data into the database
      const data = await EgniolLiveData(csvPTDataEGNIOL);
      if (data.success) {
        toast.success("Scrap CSV data successfully processed");
        setRowPTCountEGNIOL(data.rowCount);
        setCSVPTDataEGNIOL([]); // Clear the CSV data after processing
        setLodderPTEGNIOL(false);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("Error processing CSV data");
    } finally {
      setLodderPTEGNIOL(false); // Reset loader to false after CSV form submission is complete
    }
  };

  const handleSearch = async () => {
    const data = await searchNumber(searchPhoneNumber);
    console.log(data);
    if (data.success) {
      setSearchNumberDetails(data.data);
      toast.success(data.message);
      setSearchPhoneNumber("");
    } else {
      toast.error("Error: " + data.message);
    }
  };

  return (
    <div className="flex gap-5 p-8e">
      <div className="mx-auto w-[80%]  bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 className="text-2xl font-bold mb-4 text-center">Complaint Form</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Type
            </label>
            <select
              style={{ width: "100%" }}
              name="category"
              value={formData.category}
              onChange={handleChange}
            >
              <option key="select" value="" disabled>
                Select
              </option>
              <option key="dnd" value="dnd">
                Dnd
              </option>
              <option key="scrap" value="scrap">
                Scrap
              </option>
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Mobile No.
            </label>
            <input
              type="text"
              name="mobileNo"
              value={formData.mobileNo}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Mobile No."
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Email"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Company Name
            </label>
            <input
              type="text"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Company Name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              Other
            </label>
            <input
              type="text"
              name="other"
              value={formData.other}
              onChange={handleChange}
              className="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Other"
            />
          </div>
          <button
            type="submit"
            className={`bg-blue-500 text-white py-2 px-4 rounded`}
          >
            Submit
          </button>
        </form>
      </div>

      <div className="flex flex-col w-[80%] mx-auto bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <div>
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              DND CSV Form
              <Tooltip title="Sample csv file">
                <a
                  href={csvSample} // Replace 'your-file-path.csv' with the actual path to your CSV file
                  download="sample_complainForm_CSV.csv"
                  className=" text-blue-500 rounded inline-block"
                >
                  <FaDownload />
                </a>
              </Tooltip>
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVChange}
            className="mb-4"
          />
          {csvData.length > 0 && (
            <button
              onClick={handleCSVSubmit}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodder ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowCount !== null && (
            <p className="text-green-500">
              Successfully added {rowCount} numbers
            </p>
          )}
        </div>

        <div className="mt-10">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              DTMF Form
              <Tooltip title="Sample csv file">
                <a
                  href={csvSample1} // Replace 'your-file-path.csv' with the actual path to your CSV file
                  download="dtmf_sample.csv"
                  className=" text-blue-500 rounded inline-block"
                >
                  <FaDownload />
                </a>
              </Tooltip>
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVDtmfChange}
            className="mb-4"
          />
          {csvDtmfData.length > 0 && (
            <button
              onClick={handleCSVDtmfSubmit}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodderDtmf ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowDtmfCount !== null && (
            <p className="text-green-500">
              Successfully added {rowDtmfCount} numbers
            </p>
          )}
        </div>
        <div className="mt-10">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              Scrap Form
              <Tooltip title="Sample csv file">
                <a
                  href={csvSample2} // Replace 'your-file-path.csv' with the actual path to your CSV file
                  download="Scrap_sample.csv"
                  className=" text-blue-500 rounded inline-block"
                >
                  <FaDownload />
                </a>
              </Tooltip>
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVScrapChange}
            className="mb-4"
          />
          {csvScrapData.length > 0 && (
            <button
              onClick={handleCSVScrapSubmit}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodderDtmf ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowDtmfCount !== null && (
            <p className="text-green-500">
              Successfully added {rowDtmfCount} numbers
            </p>
          )}
        </div>

        {/* For Harshwardhan Bhai */}
        <div className="mt-10">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              Facebook LIGMR
              <Tooltip title="Sample csv file">
                <a
                  href={csvSample3} // Replace 'your-file-path.csv' with the actual path to your CSV file
                  download="Sample_Facebook.csv"
                  className=" text-blue-500 rounded inline-block"
                >
                  <FaDownload />
                </a>
              </Tooltip>
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVFacebookChangeLIGMR}
            className="mb-4"
              webkitdirectory=""
          />
          {csvFacebookDataLIGMR.length > 0 && (
            <button
              onClick={handleCSVFacebookSubmitLIGMR}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodderFacebookLIGMR ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowFacebookCountLIGMR !== null && (
            <p className="text-green-500">
              Successfully added {rowFacebookCountLIGMR} numbers
            </p>
          )}
        </div>
        {/* Facebook */}

        {/* For Harshwardhan Bhai */}
        <div className="mt-10">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              Facebook EGNIOL
              <Tooltip title="Sample csv file">
                <a
                  href={csvSample3} // Replace 'your-file-path.csv' with the actual path to your CSV file
                  download="Sample_Facebook.csv"
                  className=" text-blue-500 rounded inline-block"
                >
                  <FaDownload />
                </a>
              </Tooltip>
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVFacebookChangeEGNIOL}
            className="mb-4"
            webkitdirectory=""
          />
          {csvFacebookDataEGNIOL.length > 0 && (
            <button
              onClick={handleCSVFacebookSubmitEGNIOL}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodderFacebookEGNIOL ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowFacebookCountEGNIOL !== null && (
            <p className="text-green-500">
              Successfully added {rowFacebookCountEGNIOL} numbers
            </p>
          )}
        </div>

        {/*New MCA PT*/}
        <div className="mt-10">
          <div>
            <h2 className="text-2xl font-bold mb-4 text-center">
              NEW MCA PT
            </h2>
          </div>
          <input
            type="file"
            accept=".csv"
            onChange={handleCSVPTChangeEGNIOL}
            className="mb-4"
            webkitdirectory=""
          />
          {csvPTDataEGNIOL.length > 0 && (
            <button
              onClick={handleCSVPTSubmitEGNIOL}
              className="mx-2 bg-blue-500 text-white py-1 px-3 rounded"
            >
              {lodderPTEGNIOL ? "Uploading..." : "Submit"}
            </button>
          )}

          {rowPTCountEGNIOL !== null && (
            <p className="text-green-500">
              Successfully added {rowPTCountEGNIOL} numbers
            </p>
          )}
        </div>
      </div>

      <div>
        {/* Search Number Input Field */}
        <div>
          <div className="flex items-center justify-center mt-4">
            <input
              type="text"
              placeholder="Enter mobile number to search"
              value={searchPhoneNumber}
              onChange={(e) => setSearchPhoneNumber(e.target.value)}
              className="px-3 py-2 border rounded w-64"
            />
            <button
              onClick={handleSearch}
              className="ml-2 bg-blue-500 text-white py-2 px-4 rounded"
            >
              Search
            </button>
          </div>
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <h2 className="text-2xl font-bold mb-4 text-center">
              Search Result
            </h2>
            {searchNumberDetails && searchNumberDetails.existsInDND && (
              <div className="mb-4">
                <h3 className="font-semibold">Details from DND:</h3>
                <ul>
                  <li>
                    Company Name:{" "}
                    {searchNumberDetails.detailsFromDND.companyName}
                  </li>
                  <li>Email: {searchNumberDetails.detailsFromDND.email}</li>
                  <li>
                    Mobile Number: {searchNumberDetails.detailsFromDND.mobileNo}
                  </li>
                  <li>Other: {searchNumberDetails.detailsFromDND.other}</li>
                </ul>
              </div>
            )}
            {searchNumberDetails && searchNumberDetails.existsInScrap && (
              <div className="mb-4">
                <h3 className="font-semibold">Details from Scrap:</h3>
                <p>MobileNumber : {searchNumberDetails.mobileNumber}</p>
              </div>
            )}
            {!searchNumberDetails.existsInDND &&
              !searchNumberDetails.existsInScrap && (
                <p>
                  No details found for the mobile number{" "}
                  {searchNumberDetails.mobileNumber}
                </p>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
