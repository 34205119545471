import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const DialerTimeGraph = ({ filteredData, CallStatus }) => {
  const [data, setData] = useState([]);

  const getAllSourceTotal = (total, hour) => {
    const pvtAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalCalls
      : 0;
    const pvtMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["PVT"].totalCalls
      : 0;
    const gstAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalCalls
      : 0;
    const gstMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["GST"].totalCalls
      : 0;
    const unAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalCalls
      : 0;
    const unMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["UNKNOWN"].totalCalls
      : 0;
    let pvtInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalInterested
      : 0;
    let gstInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalInterested
      : 0;
    let unInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalInterested
      : 0;
    const totalInterested = pvtInt + gstInt + unInt;
    return {
      pvtAnsCalls,
      pvtMisCalls,
      gstAnsCalls,
      gstMisCalls,
      unAnsCalls,
      unMisCalls,
      totalInterested,
    };
  };

  const particularCallStatusAllSourceTotal = (total, hour) => {
    const pvtCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["PVT"].totalCalls
      : 0;
    const gstCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["GST"].totalCalls
      : 0;
    const unCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["UNKNOWN"].totalCalls
      : 0;
    let pvtInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalInterested
      : 0;
    let gstInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalInterested
      : 0;
    let unInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalInterested
      : 0;
    const totalInterested = pvtInt + gstInt + unInt;
    return {
      pvtCalls,
      gstCalls,
      unCalls,
      totalInterested,
    };
  };

  const processFilteredData = () => {
    let processedData = [];
    const total = JSON.parse(filteredData[0].total);

    Object.keys(total).forEach((hour) => {
      if (CallStatus === "ALL") {
        const {
          pvtAnsCalls,
          pvtMisCalls,
          gstAnsCalls,
          gstMisCalls,
          unAnsCalls,
          unMisCalls,
          totalInterested,
        } = getAllSourceTotal(total, hour);
        processedData.push({
          name: hour,
          gstTotalCalls: gstAnsCalls + gstMisCalls,
          pvtTotalCalls: pvtAnsCalls + pvtMisCalls,
          unknownTotalCalls: unAnsCalls + unMisCalls,
        });
      } else {
        const { pvtCalls, gstCalls, unCalls, totalInterested } =
          particularCallStatusAllSourceTotal(total, hour);
        processedData.push({
          name: hour,
          gstTotalCalls: gstCalls,
          pvtTotalCalls: pvtCalls,
          unknownTotalCalls: unCalls,
        });
      }
    });

    setData(processedData);
  };

  useEffect(() => {
    processFilteredData();
  }, [filteredData, CallStatus]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <LineChart width={500} height={500} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Legend />
        <Tooltip />
        <CartesianGrid strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="pvtTotalCalls"
          stroke="#E178C5"
          fill="#E178C5"
        />
        <Line
          type="monotone"
          dataKey="gstTotalCalls"
          stroke="#7743DB"
          fill="#7743DB"
        />
        <Line
          type="monotone"
          dataKey="unknownTotalCalls"
          stroke="#36A2EB"
          fill="#36A2EB"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DialerTimeGraph;
