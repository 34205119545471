import React, { useState, useEffect } from "react";
import { MdOutlineFileDownload } from "react-icons/md";

import moment from "moment";

// PNG Utils
import html2canvas from "html2canvas";

const CallingReport = ({ dataSource, totalAnswered, totalMissed, Date }) => {
  const keys = [
    { label: "SR NO.", key: "Header" },
    { label: "AGENT NAME", key: "Agent_Name" },
    { label: "AGENT NUMBER", key: "Agent_Number" },
    { label: "TOTAL AWNSERED CALLS", key: "Total_Awnsered" },
    { label: "TOTAL CALLS DURATION (MINUTES)", key: "Total_Duration" },
    { label: "AVG. MIN PER CALL", key: "Average_Min_Per_Call" },
  ];

  const [summary, setSummary] = useState([]);

  function formatDuration(totalDurationInMinutes) {
    // Convert minutes to hours, minutes, and seconds
    var hours = Math.floor(totalDurationInMinutes / 60);
    var minutes = Math.floor(totalDurationInMinutes % 60);
    var seconds = Math.floor((totalDurationInMinutes * 60) % 60);

    // Format the result as hh:mm:ss
    var formattedDuration =
      ("0" + hours).slice(-2) +
      ":" +
      ("0" + minutes).slice(-2) +
      ":" +
      ("0" + seconds).slice(-2);

    return formattedDuration;
  }

  // Utils for downloadin the CSV
  const captureAndDownload = () => {
    const element = document.getElementById("DID_CALLS_REPORT"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Dialer-Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  useEffect(() => {
    const totalAnsweredDuration = parseFloat(
      (totalAnswered.didDuration || 0) / 60
    ).toFixed(2);
    const totalMissedDuration = parseFloat(
      (totalMissed.didDuration || 0) / 60
    ).toFixed(2);
    const totalDuration = parseFloat(
      (totalAnswered.didDuration + totalMissed.didDuration || 0) / 60
    ).toFixed(2);
    let AVG_OF_AVG_DURATION_PER_MIN = 0;
    dataSource.map((data) => {
      AVG_OF_AVG_DURATION_PER_MIN += data.Average_Min_Per_Call;
    });

    let summary = [
      {
        // Header: "TOTAL_ANSWERED_CALLS",
        Label: "TOTAL ANSWERED CALLS",
        TotalCalls: totalAnswered.didCalls,
        TotalDuration: formatDuration(totalAnsweredDuration),
        TotalAvgDuration: Math.round(
          AVG_OF_AVG_DURATION_PER_MIN / dataSource.length
        ),
      },
      {
        // Header: "TOTAL_MISSED_CALLS",
        Label: "TOTAL MISSED CALLS",
        TotalCalls: totalMissed.didCalls,
        TotalDuration: formatDuration(totalMissedDuration),
        TotalAvgDuration: 0,
      },
      {
        // Header: "TOTAL",
        Label: "TOTAL",
        TotalCalls: totalAnswered.didCalls + totalMissed.didCalls,
        TotalDuration: formatDuration(totalDuration),
        TotalAvgDuration: 0,
      },
    ];
    setSummary(summary);
  }, [dataSource]);

  return (
    <div>
      <div className="mx-auto w-fit">
        <div className="flex justify-between">
          <p className="font-bold text-2xl">{Date ? `Date: ${Date}` : "All"}</p>
          <button
            onClick={() => captureAndDownload()}
            className="text-blue-500 text-2xl"
          >
            <MdOutlineFileDownload />
          </button>
        </div>
        <table className="m-auto" id="DID_CALLS_REPORT">
          <thead className="border border-black">
            <tr className="text-center">
              <th
                className="text-center border-black border-2 bg-[#FFFF00] font-bold"
                colSpan={7}
              >
                WEBSITE TALKTIME REPORT
              </th>
            </tr>
            <tr className="border">
              {keys.map((key) => (
                <th
                  className="border-2 text-center p-2 border-black  bg-[#FFFF00]"
                  key={key}
                >
                  {key.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {dataSource.map((data, dataIndex) => {
              return (
                <tr key={dataIndex}>
                  {keys.map(({ key }, index) => {
                    return (
                      <td
                        className={`border-2 text-center p-1 font-bold border-black`}
                        key={index}
                      >
                        {key === "Header" ? dataIndex + 1 : data[key]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
            {summary.map((data, dataIndex) => {
              return (
                <tr key={dataIndex}>
                  {Object.keys(data).map((key, index) => {
                    if (key === "Label") {
                      return (
                        <td
                          className={`border-2 ${
                            data["Label"] === "TOTAL MISSED CALLS"
                              ? " bg-[#F4AE85] "
                              : data["Label"] === "TOTAL"
                              ? " bg-[#9BC1E6] "
                              : " bg-[#FEFE00] "
                          } text-center p-1 font-bold border-black`}
                          key={index}
                          colSpan={3}
                        >
                          {data[key]}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          className={`border-2 text-center ${
                            data["Label"] === "TOTAL MISSED CALLS"
                              ? " bg-[#F4AE85] "
                              : data["Label"] === "TOTAL"
                              ? " bg-[#9BC1E6] "
                              : " bg-[#FEFE00] "
                          } p-1 font-bold border-black`}
                          key={index}
                        >
                          {(key === "TotalDuration" ||
                            key === "TotalAvgDuration") &&
                          (data["Label"] === "TOTAL MISSED CALLS" ||
                            data["Label"] === "TOTAL")
                            ? "-"
                            : data[key]}
                        </td>
                      );
                    }
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CallingReport;
