import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { incomingDisporeport } from "../../api/report";
import moment from "moment";
const Disporeport = () => {
  const [reportData, setReportData] = useState({});
  const [loading, setLoading] = useState(true);

  const fetchFromBackend = async () => {
    try {
      const data = await incomingDisporeport();
      if (data.success) {
        setReportData(data.data);
      }
    } catch (error) {
      console.log("data fetching", error);
    } finally {
      setLoading(false); // Set loading to false regardless of success or failure
    }
  };

  useEffect(() => {
    fetchFromBackend();
  }, []);

  // Calculate grand total
  const grandTotal = Object.values(reportData).reduce(
    (total, count) => total + count,
    0
  );

  // Remove 'Grand Total' from reportData for display purposes
  const { GrandTotal, ...displayData } = reportData;

  const entries = Object.entries(displayData);

  return (
    <div className="container mx-auto p-4 text-center font-bold text-base">
      <Spin spinning={loading}>
        <table className=" bg-white border border-gray-200">
          <thead>
            <tr>
              <td
                colSpan={3}
                className="border-2 px-2 border-black bg-[#FEFF00]"
              >
                INCOMING DISPO - {moment().format("DD/MM/YYYY")}
              </td>
            </tr>
            <tr>
              <th className="border-2 p-1 border-black bg-[#FEFF00]">
                Sr. No.
              </th>
              <th className="border-2 p-1 border-black bg-[#FEFF00]">Status</th>
              <th className="border-2 p-1 border-black bg-[#FEFF00]">Count</th>
            </tr>
          </thead>
          <tbody>
            {entries.map(([status, count], index) => (
              <tr key={index} className="border border-black ">
                <td
                  className={`border-2 px-1 border-black ${
                    status === "Missed" ? "text-red-600" : ""
                  }
                  ${status === "Interested" ? "bg-[#8EAADB]" : ""}
                  `}
                >
                  {index + 1}
                </td>
                <td
                  className={`border-2 px-1 border-black ${
                    status === "Missed" ? "text-red-600" : ""
                  }
                  ${status === "Interested" ? "bg-[#8EAADB]" : ""}
                  `}
                >
                  {status}
                </td>
                <td
                  className={`border-2 px-1 border-black ${
                    status === "Missed" ? "text-red-600" : ""
                  }
                  ${status === "Interested" ? "bg-[#8EAADB]" : ""}
                  `}
                >
                  {count}
                </td>
              </tr>
            ))}
            <tr className="border border-black">
              <td
                className="border-2 border-black font-bold bg-[#FEFF00]"
                colSpan={2}
              >
                Grand Total
              </td>
              <td className="border-2 border-black font-bold bg-[#FEFF00]">
                {grandTotal}
              </td>
            </tr>
          </tbody>
        </table>
      </Spin>
    </div>
  );
};

export default Disporeport;
