import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';

const DailyReport = ({ dailyReportData }) => {
    const [loading, setLoading] = useState(true);
    //2024-04-10 format
    const currentDate = new Date().toLocaleDateString('en-IN', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        timeZone: 'Asia/Kolkata'
    }).split('/').reverse().join('-');

    console.log(currentDate, "date");


    const colors = [
        '#FF6384', // Red
        '#36A2EB', // Blue
        '#FFCE56', // Yellow
        '#008000', // Green
        '#FFA500', // Orange
        '#FF69B4', // Hot Pink
        '#800080', // Purple
        '#A52A2A', // Brown
        '#000000', // Black
        '#808080', // Grey
    ];


    const sourceInData = dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceIn'] || {};
    const labelsIn = Object.keys(sourceInData);
    const valuesIn = Object.values(sourceInData);

    const sourceOutData = dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceOut'] || {};
    const labelsOut = Object.keys(sourceOutData);
    const valuesOut = Object.values(sourceOutData);

    const sourceMissedData = dailyReportData?.[currentDate]?.['DialerMissedCalls']?.['sourceMissed'] || {};
    const labelsMissed = Object.keys(sourceMissedData);
    const valuesMissed = Object.values(sourceMissedData);

    const dataSetsIn = {
        labels: labelsIn,
        datasets: [{
            data: valuesIn, // Assuming values is an array of numbers
            backgroundColor: colors.slice(0, labelsIn.length),
            hoverBackgroundColor: colors.slice(0, labelsIn.length)
        }]
    };
    const dataSetsOut = {
        labels: labelsOut,
        datasets: [{
            data: valuesOut, // Assuming values is an array of numbers
            backgroundColor: colors.slice(0, labelsOut.length),
            hoverBackgroundColor: colors.slice(0, labelsOut.length)
        }]
    };
    const dataSetsMissed = {
        labels: labelsMissed,
        datasets: [{
            data: valuesMissed, // Assuming values is an array of numbers
            backgroundColor: colors.slice(0, labelsMissed.length),
            hoverBackgroundColor: colors.slice(0, labelsMissed.length)
        }]
    };


    useEffect(() => {
        setLoading(!dailyReportData || !dailyReportData[currentDate]);
    }, [dailyReportData, currentDate]);

    return (
        <div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                dailyReportData ? (
                    <div>
                        <div className="flex justify-center mt-5 mb-5">
                            <h1 className="p-5 text-center text-3xl font-semibold">
                                <span className="border-black p-2 rounded">Daily Dialer Report</span>
                                <span className="border-black p-2 rounded"> [ {currentDate} ] </span>
                            </h1>
                        </div>

                        <div>
                            <div className="my-10">
                                <table className="p-3 m-auto w-[90%]" id="Collections">
                                    <thead className="p-3 border-2 border-black mb-5 bg-[#FFFF00]">
                                        <tr className="font-bold mb-5">
                                            <th colSpan={3} className=" border-2 border-black p-2 font-bold bg-[#FFFF00]">
                                                Dialer Answered Calls
                                            </th    >
                                            <th colSpan={3} className=" border-2 border-black p-2 font-bold bg-[#FFFF00]">
                                                Dialer Missed Calls
                                            </th>
                                        </tr>
                                        <tr>
                                            <th className='p-2'>Total Calls</th>
                                            <th className='p-2'>InBound</th>
                                            <th className='p-2'>Outbound</th>
                                            <th className='p-2'>Total Calls</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['totalCalls'] || '-'}
                                            </td>
                                            {/* <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['totalAnsweredCalls'] || '-'}
                                            </td> */}
                                            <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['inbound'] || '-'}
                                            </td>
                                            <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['outbound'] || '-'}
                                            </td>
                                            <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]['DialerMissedCalls']?.['totalCalls'] || '-'}
                                            </td>
                                            {/* <td className="p-2 border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                                                {dailyReportData?.[currentDate]['DialerMissedCalls']?.['totalMissedCalls'] || '-'}
                                            </td> */}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='flex  justify-center'>
                                <div style={{ width: '350px', height: '350px' }}>
                                    <span className='flex font-bold text-center p-1 justify-center'>Incoming Source</span>
                                    <Pie
                                        data={dataSetsIn}
                                    />
                                </div>
                                <div style={{ width: '370px', height: '370px' }}>
                                    <span className='flex font-bold text-center p-1 justify-center'>Outgoing Source</span>
                                    <Pie
                                        data={dataSetsOut}
                                    />
                                </div>
                                <div style={{ width: '350px', height: '350px' }}>
                                    <span className='flex font-bold text-center p-1 justify-center'>Missed Source</span>
                                    <Pie
                                        data={dataSetsMissed}
                                    />
                                </div>
                            </div>
                            <div className="my-10">
                                <table className="m-auto w-[90%]" id="Collections">
                                    <thead className="border-2 border-black mb-5 bg-[#FFFF00]">
                                        <tr className="font-bold mb-5">
                                            <th className="border-2 border-black p-1 bg-[#FFFF00]">
                                                Dialer Answered's Source
                                            </th>
                                            <th className="border-2 border-black p-1 bg-[#FFFF00]">
                                                Dialer Missed's Source
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className="flex border-2 border-black p-1 justify-center">
                                                <div>
                                                    <span className='flex font-bold text-center p-1 justify-center'>Incoming Source</span>
                                                    <div>
                                                        <ul className="divide-y divide-gray-400">
                                                            {Object.entries(dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceIn'] || {}).map(([key, value]) => (
                                                                <li className="py-2 px-4  font-semibold text-center" key={key}>
                                                                    <span>{key} : </span> {value}
                                                                </li>
                                                            ))}
                                                            <li className="py-2 px-4  font-semibold text-center">
                                                                <span className=' bg-slate-400 p-3'>Total: {Object.values(dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceIn'] || {}).reduce((acc, curr) => acc + curr, 0)}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div>
                                                    <span className='flex font-bold text-center p-1 justify-center'>Outgoing Source</span>
                                                    <div>
                                                        <ul className="divide-y divide-gray-400">
                                                            {Object.entries(dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceOut'] || {}).map(([key, value]) => (
                                                                <li className="py-2 px-4  font-semibold text-center" key={key}>
                                                                    <span>{key} : </span> {value}
                                                                </li>
                                                            ))}
                                                            <li className="py-2 px-4  font-semibold text-center">
                                                                <span className=' bg-slate-400 p-3'> Total: {Object.values(dailyReportData?.[currentDate]?.['DialerAnsweredCalls']?.['sourceOut'] || {}).reduce((acc, curr) => acc + curr, 0)}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="border-2 border-black p-1" >
                                                <span className='flex font-bold text-center p-1 justify-center'>Incoming Source</span>
                                                <div>
                                                    <ul className="divide-y divide-gray-400">
                                                        {Object.entries(dailyReportData?.[currentDate]?.['DialerMissedCalls']?.['sourceMissed'] || {}).map(([key, value]) => (
                                                            <li className="py-2 px-4  font-semibold text-center" key={key}>
                                                                <span >{key} : </span> {value}
                                                            </li>
                                                        ))}
                                                        <li className="py-2 px-4  font-semibold text-center">
                                                            <span className=' bg-slate-400 p-3'>Total: {Object.values(dailyReportData?.[currentDate]?.['DialerMissedCalls']?.['sourceMissed'] || {}).reduce((acc, curr) => acc + curr, 0)}</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                ) : (
                    <p>No data available.</p>
                )
            )}
        </div >
    );
}

export default DailyReport;

