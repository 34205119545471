import React, { useState } from "react";
import { DatePicker, Button, Spin } from "antd"; // Import Spin for loader
import { exportData } from "../../api/export_data";
import { CSVLink } from "react-csv";

const { RangePicker } = DatePicker;

const ExportData = () => {
  const [dateRange, setDateRange] = useState([]);
  const [excelData, setExcelData] = useState([]);
  const [loader, setLoader] = useState(false); // Change variable name to loader
  const handleDateRangeChange = (dates, dateStrings) => {
    setDateRange(dateStrings);
  };

  let headers = [
    { label: "Contact", key: "Contact" },
    { label: "Company_Name", key: "Company_Name" },
    { label: "Email", key: "Email" },
    { label: "Source", key: "Source" },
    { label: "Service", key: "Service" },
    { label: "created_at", key: "created_at" },
  ];

  const handleExport = async () => {
    setLoader(true); // Start loader
    try {
      const data = await exportData(dateRange);

      if (data.success) {
        setExcelData(Object.values(data.data).flat());
      }
    } catch (error) {
      console.log("fetching error", error);
    } finally {
      setLoader(false); // Turn off loader
    }
  };

  const handleDownload = () => {
    document.getElementById("csv-link").click();
  };

  return (
    <div style={{ margin: "20px" }}>
      <h2>Select Date Range:</h2>
      <RangePicker
        showTime={{ format: "HH:mm:ss" }}
        format="YYYY-MM-DD HH:mm:ss"
        onChange={handleDateRangeChange}
      />
      <CSVLink
        id="csv-link"
        data={excelData}
        headers={headers}
        filename={`Exported_data.csv`}
      />
      <Button
        type="primary"
        style={{ marginTop: "10px" }}
        onClick={handleExport}
        disabled={loader}
      >
        {loader ? <Spin /> : "Export Data"} {/* Use Spin component as loader */}
      </Button>
      <Button onClick={handleDownload}>Download</Button>
    </div>
  );
};

export default ExportData;
