import React from "react";
const Welcome = () => {
  return (
    <div className="mx-auto p-8 bg-white w-full rounded-lg">
      <div className="text-center mb-8">
        <p className="font-semibold text-3xl mb-4">
          Good Morning QRT Team 👋 !
        </p>
      </div>
      <blockquote className="text-center mx-auto">
        "Alright, listen up! Welcome to the Quick Response Team, mate! 😎👋 We
        ain't just about workin' round the clock, we bloody own that clock,
        understand? ⏰💪 So, get yer gears grindin' 'cause in QRT, sleep's as
        distant as a faded memory! 😴✨ Let's bloody rock this 24/7 hustle
        together, eh? 🚀🌟"
      </blockquote>
    </div>
  );
};

export default Welcome;
