import React, { useEffect, useState } from "react";

function secondsToHMS(seconds) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

const DNDReport = ({ dataSource }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(30);
  const [allData, setAllData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [grandTotal, setGrandTotal] = useState({
    totalCalls: 0,
    totalDuration: 0,
    avgCallDuration: 0,
  });
  const [pageGrandTotal, setPageGrandTotal] = useState({
    totalCalls: 0,
    totalDuration: 0,
    avgCallDuration: 0,
  });

  const handlePagination = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatData = () => {
    let gTotal = {
      totalCalls: 0,
      totalDuration: 0,
      avgCallDuration: 0,
    };

    let formattedData = dataSource.map((data) => {
      const dndAnsCalls = data["ANSWERED"]
        ? data["ANSWERED"]["DND"].totalCalls
        : 0;
      const dndMisCalls = data["MISSED"] ? data["MISSED"]["DND"].totalCalls : 0;
      const dndAnsDuration = data["ANSWERED"]
        ? data["ANSWERED"]["DND"].totalDuration
        : 0;
      const dndMisDuration = data["MISSED"]
        ? data["MISSED"]["DND"].totalDuration
        : 0;

      const formattedData = {
        Agent_Name: data.Agent_Name,
        totalCalls: dndAnsCalls + dndMisCalls,
        totalDuration: dndAnsDuration + dndMisDuration,
        avgCallDuration: Math.ceil(
          (dndAnsDuration + dndMisDuration) / (dndAnsCalls + dndMisCalls)
        ),
      };
      return formattedData;
    });
    formattedData = formattedData
      .filter(
        (data) =>
          data.totalCalls !== 0 &&
          data.totalDuration !== 0 &&
          data.Agent_Name !== null
      )
      .sort((a, b) => b.totalCalls - a.totalCalls);
    formattedData.forEach((data) => {
      gTotal.totalCalls += data.totalCalls;
      gTotal.totalDuration += data.totalDuration;
      gTotal.avgCallDuration += data.avgCallDuration;
    });

    gTotal.totalDuration = secondsToHMS(gTotal.totalDuration);
    setGrandTotal(gTotal);
    setAllData(formattedData);
  };

  useEffect(() => {
    formatData();
  }, [dataSource]);

  useEffect(() => {
    const startIndex = (currentPage - 1) * recordsPerPage;
    const endIndex = startIndex + recordsPerPage;
    const newData = allData.slice(startIndex, endIndex);
    let gTotal = {
      totalCalls: 0,
      totalDuration: 0,
      avgCallDuration: 0,
    };
    newData.map((data) => {
      gTotal.totalDuration += data.totalDuration;
      gTotal.totalCalls += data.totalCalls;
    });

    setPageGrandTotal(gTotal);

    setDisplayedData(newData);
  }, [currentPage, recordsPerPage, allData]);

  return (
    <div>
      <div className="justify-center mt-5 mb-5">
        <h1 className="p-5 text-center text-3xl font-semibold">
          <span className="border-black p-2 rounded">DND Report</span>
        </h1>
        <p className="text-red-500 text-center text-lg">
          ---------- NOT READY YET ----------
        </p>
      </div>
      <table className="m-auto w-[80%]" id="Collections">
        <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
          <tr className="font-bold mb-5">
            <th colSpan={3} className="border-2 border-black p-1">
              GRAND TOTAL
            </th>
          </tr>
          <tr className="font-bold mb-5">
            <th className=" border-2 border-black p-1">TOTAL CALLS</th>
            <th className=" border-2 border-black p-1">TOTAL DURATION</th>
          </tr>
        </thead>
        <tbody className="tetx-center">
          <tr className="font-bold bg-green-300">
            <td className="border-2 text-center border-black">
              {grandTotal.totalCalls}
            </td>
            <td className="border-2 text-center border-black">
              {grandTotal.totalDuration}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="my-10">
        <table className="m-auto w-[80%]" id="Collections">
          <thead className="border-2  border-black mb-5 bg-[#FFFF00]">
            <tr className="font-bold mb-5">
              <th colSpan={5} className="border-2 border-black p-1">
                DND CALLS REPORT
              </th>
            </tr>
            <tr className="font-bold mb-5">
              <th className="border-2 border-black p-1">SR NO.</th>
              <th className=" border-2 border-black p-1">AGENT NAME</th>
              <th className=" border-2 border-black p-1">TOTAL CALLS</th>
              <th className=" border-2 border-black p-1">TOTAL DURATION</th>
              <th className=" border-2 border-black p-1">AVG. DURATION</th>
            </tr>
          </thead>
          <tbody className="">
            {displayedData.map((data, index) => {
              return (
                <tr className="font-bold">
                  <td className="border-2 border-black p-1">
                    {recordsPerPage * (currentPage - 1) + (index + 1)}
                  </td>
                  <td className="border-2 border-black p-1">
                    {data.Agent_Name}
                  </td>
                  <td className="border-2 border-black p-1">
                    {data.totalCalls}
                  </td>
                  <td className="border-2 border-black p-1">
                    {secondsToHMS(data.totalDuration)}
                  </td>
                  <td className="border-2 border-black p-1">
                    {secondsToHMS(data.avgCallDuration)}
                  </td>
                </tr>
              );
            })}
            <tr className="font-bold bg-[#DEAA78]">
              <td colSpan={2} className="border-2 border-black p-1">
                GRAND TOTAL
              </td>
              <td className="border-2 border-black p-1">
                {pageGrandTotal.totalCalls}
              </td>
              <td className="border-2 border-black p-1">
                {secondsToHMS(pageGrandTotal.totalDuration)}
              </td>
              <td className="border-2 border-black p-1">-</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="pagination flex justify-center">
        {Array.from({
          length: Math.ceil(allData.length / recordsPerPage),
        }).map((_, index) => (
          <button
            className={`text-center align-center w-8 h-8 bg-orange-400 shadow-md hover:text-lg transition-transform duration-300 ease-in-out ${
              index + 1 === currentPage ? "animate-bounce" : ""
            } hover:shadow-lg rounded-md font-semibold mt-4 mx-3`}
            key={index}
            onClick={() => handlePagination(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DNDReport;
