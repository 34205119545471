import { DatePicker } from 'antd';
// PNG Utils
import html2canvas from "html2canvas";
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { MdOutlineFileDownload } from "react-icons/md";

const Collections = ({ dataSourceCollection }) => {
    const [temp, setTemp] = useState(dataSourceCollection);
    const [data, setData] = useState(dataSourceCollection);
    const [selectedDate, setSelectedDate] = useState(null);
    const [totalCollections, setTotalCollections] = useState(0);

    // Utils for downloadin the CSV
    const captureAndDownload = () => {
        const element = document.getElementById("Collections"); // ID of the element to capture
        html2canvas(element).then((canvas) => {
            const link = document.createElement("a");
            link.href = canvas.toDataURL("image/png");
            link.download = `Sales-Collections_${moment().format(
                "YYYY-MM-DD HH:mm:ss"
            )}.png`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    };


    useEffect(() => {
        setData(dataSourceCollection);
    }, [dataSourceCollection]);

    const formatDate = (date) => {
        const day = ('0' + date.getDate()).slice(-2); // Add leading zero if needed
        const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero if needed
        const year = date.getFullYear().toString().slice(-2); // Get last two digits of the year
        return `${day}/${month}/${year}`;
    };


    const handleDateChange = (date) => {
        if (!date) {
            setData(temp);
            setSelectedDate(null);
            return;
        }
        const formattedDate = formatDate(date.toDate());
        const filteredData = dataSourceCollection.filter(item => {
            const itemYear = moment(item.Date, "DD/MM/YYYY").format("YY"); // Extract last 2 digits of the year
            const formattedItemDate = formatDate(moment(item.Date, "DD/MM/YYYY").toDate());
            return formattedDate === formattedItemDate;
        });
        setData(filteredData);
    };


    // Calculate total collections
    useEffect(() => {
        // const total = data.reduce((acc, curr) => acc + parseFloat(curr.Total_Received_Amount), 0);
        const total = data.map(item => parseFloat(item.Total_Received_Amount)).reduce((acc, curr) => acc + curr, 0);
        setTotalCollections(total);
    }, [data]);

    return (
        <div>
            <div className='justify-center mt-10 mb-10'>
                <h1 className='p-5 text-center text-2xl font-bold'>
                    <span className='bg-green-400 p-2 rounded'>Collections - 2024</span>
                </h1>
            </div>
            <div className='flex'>
                <DatePicker onChange={handleDateChange} />
                <button
                    onClick={() => captureAndDownload()}
                    className="text-blue-500 text-2xl"
                >
                    <MdOutlineFileDownload className='ml-5' />
                </button>
            </div>

            <div className='flex justify-center'>
                <table className="m-auto" id="Collections">
                    <thead className='border-2  border-black mb-5 bg-[#FFFF00]'>
                        <tr className='font-bold mb-5 text-2xl'>
                            <th className='mb-5 border-2 border-black p-5'>Sr. Number</th>
                            <th className='mb-5 border-2 border-black p-5'>Employee Name</th>
                            <th className='mb-5 border-2 border-black p-5'>Collections</th>
                        </tr>
                    </thead>
                    <tbody className='pt-5'>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}>{index + 1}</td>
                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}>{item.BDM_Name}</td>
                                <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}>{item.Total_Received_Amount} ₹</td>
                            </tr>
                        ))}
                        <tr className='border-2  text-center border-black mb-5 bg-[#FFFF00]'>
                            <td className={`text-center text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}></td>
                            <td className={`text-center  text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}>Total Collections</td>
                            <td className={`text-center border-2 text-center" bg-[#9BC1E6]"} p-2 font-bold border-black`}>
                                {totalCollections} ₹</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    );
};

export default Collections;

