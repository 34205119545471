import React from 'react';
import { Tabs } from 'antd';
import IvrForm from './IvrForm'; // Import your IvrForm component here
import GetIvrFormData from './GetIvrFormData';
const { TabPane } = Tabs;

const IvrHeader = ({setIsModalVisible}) => {
  return (
    <Tabs defaultActiveKey="1" className='font-semibold'>
      <TabPane tab="IvrServiceForm" key="1">
        <IvrForm setIsModalVisible={setIsModalVisible}/>
      </TabPane>
      <TabPane tab="IvrForm" key="2">
      <GetIvrFormData/>
      </TabPane>
    </Tabs>
  );
};

export default IvrHeader;
