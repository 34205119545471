// src/GroupedInsights.js
import React from "react";

const GroupedInsights = ({ title, data }) => {
  return (
    <div className=" mt-6">
      <h2 className="text-xl text-center underline">{title}</h2>
      <table className="table-auto w-full border-collapse border rounded-md border-gray-200">
        <thead>
          <tr>
            <th className="border border-black px-4 py-2 bg-gray-100">
              Category
            </th>
            <th className="border border-black px-4 py-2 bg-gray-100">Count</th>
          </tr>
        </thead>
        <tbody>
          {data.map(({ key, count }) => (
            <tr
              key={key}
              className={`${
                count < 100 ? "bg-red-300" : "bg-green-100"
              } rounded-md font-semibold text-center`}
            >
              <td className="border border-black px-4 py-2">{key}</td>
              <td className={`border border-black px-4 py-2`}>{count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GroupedInsights;
