import React, { useEffect, useState, useRef } from "react";
import { getAirtelSIMCallsData } from "../../../api/airtel";
import { Link } from "react-router-dom";
import { Table, Input, Button, Space, Spin } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import GraphContainer from "../Graph/Dialer/DialerGraphContainer";
import TimeSeriesGraph from "../Graph/Dialer/DialerDateGraph";

const Airtel = () => {
  const [callsData, setCallsData] = useState([]);
  const [keys, setKeys] = useState([]);
  const [view, setView] = useState(
    "freq_call_employee_to_employee_per_month_record"
  );
  const [loading, setLoading] = useState(false);

  const [dataSource, setDataSource] = useState([]);

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  // For suearching logic in the ANTD Table

  const getCallsData = async (view) => {
    setLoading(true);
    const data = await getAirtelSIMCallsData({
      view,
    });
    if (data.success) {
      const res = data.result;
      setCallsData(res);
      setDataSource(res);
      setKeys(data.keys);
    } else {
      console.log(data.error);
    }
    setLoading(false);
  };

  const handleChange = (changedView) => {
    setView(changedView);
    getCallsData(changedView);
  };
  useEffect(() => {
    getCallsData(view);
  }, []);

  const [text, setText] = useState("");

  const columns = keys.map((key) => ({
    title:
      key === "Call_Frequency" || key === "Call Duration" ? (
        <span className="text-orange-500">{key}</span>
      ) : (
        key
      ),
    dataIndex: key,
    key: key,
    align: "center",
    ...(key === "Total_Calls" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Inbound_Calls" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Outbound_Calls" && { sorter: (a, b) => a[key] - b[key] }),
    // ...(key === "Call_Frequency" && { sorter: (a, b) => a[key] - b[key] }),
    // ...(key === "Call_Frequency" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Duration" && {
      sorter: (a, b) => a[key] - b[key],
    }),
    ...(key === "Call Duration" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Call_Duration" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Total_Call_Duration" && { sorter: (a, b) => a[key] - b[key] }),
    ...(key === "Agent_Number" && {
      sorter: (a, b) => a[key] - b[key],
      ...getColumnSearchProps("Agent_Number"),
    }),
    ...(key === "Caller Agent Name" && {
      ...getColumnSearchProps("Caller Agent Name"),
    }),
    ...(key === "Company Name" && {
      ...getColumnSearchProps("Company Name"),
    }),
    ...(key === "source" && {
      ...getColumnSearchProps("source"),
    }),
    ...(key === "Customer_Number" && {
      ...getColumnSearchProps("Customer_Number"),
    }),
    ...(key === "Destination Agent Name" && {
      ...getColumnSearchProps("Destination Agent Name"),
    }),
  }));

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl font-bold mb-4">Airtel Data</h1>
      <div className="flex flex-row justify-between">
        <div className="flex flex-col">
          <label className="font-bold">Total Calls</label>
          <select
            className="bg-orange-500 custom-select text-white px-4 py-2 rounded-md mb-4 mr-4 p-5"
            value={view}
            onChange={(event) => {
              handleChange(event.target.value);
            }}
          >
            <option
              value="freq_call_done_by_customer_to_agent"
              className="bg-white text-black"
            >
              FREQ. CALL DONE BY CUSTOMER TO AGENT
            </option>

            <option
              value="freq_call_employee_to_employee_per_month_record"
              className="bg-white text-black"
            >
              FREQ. CALL EMPLOYEE TO EMPLOYEE
            </option>
          </select>
        </div>
      </div>

      {loading ? (
        <div className="min-h-screen min-w-screen flex justify-center items-center">
          <Spin />
        </div>
      ) : (
        <Table columns={columns} dataSource={dataSource} />
      )}
    </div>
  );
};

export default Airtel;

// {
//   /* <div className="flex flex-row">
//           <button className="mx-auto  border-orange-500 rounded-full text-orange-500 px-4 py-2 mt-5 hover:underline hover:border-blue-900 hover:cursor-pointer">
//             <Link to="/agent-customer-airtel-report">
//               Airtel All Source Report
//             </Link>
//           </button>
//           <button className="mx-auto  border-orange-500 rounded-full text-orange-500 px-4 py-2 mt-5 hover:underline hover:border-blue-900 hover:cursor-pointer">
//             <Link to="/dialer-report-airtel">Dialer all Source Report</Link>
//           </button>
//           <button className="mx-auto  border-orange-500 rounded-full text-orange-500 px-4 py-2 mt-5 hover:underline hover:border-blue-900 hover:cursor-pointer">
//             <Link to="/employee">Employee</Link>
//           </button>
//           <button className="mx-auto  border-orange-500 rounded-full text-orange-500 px-4 py-2 mt-5 hover:underline hover:border-blue-900 hover:cursor-pointer">
//             <Link to="/class-wise-employee-data">
//               Employee To Customer Data
//             </Link>
//           </button>
//           <button className="mx-auto  border-orange-500 rounded-full text-orange-500 px-4 py-2 mt-5 hover:underline hover:border-blue-900 hover:cursor-pointer">
//             <Link to="/false-dnd-input-data">DND False Input Data</Link>
//           </button>
//         </div> */
// }
