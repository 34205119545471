import client from "./client";

export const getMenuLeads = async (startStamp, endStamp) => {
  try {
    const { data } = await client.post(`/menuleads/get-menu-leads`, {
      startStamp,
      endStamp,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const uploadMenuLeads = async (obj) => {
  try {
    const { data } = await client.post(`/menuleads/upload-menu-leads`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};


export const insertMenuLeads = async (obj) => {
  try {
    const { data } = await client.post(`/menuleads/insert-menu`, { data: obj });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getFilteredMenuLeads = async (obj) => {
  try {
    const { data } = await client.post(`/menuleads/all-menuleads`, obj);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const removeMenuLeads = async (date) => {
  try {
    const { data } = await client.delete(`/menuleads/delete-menuleads/${date}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
export const getMenuLeadsCount = async (date) => {
  try {
    const { data } = await client.get(`/menuleads/get-menuleads-count/${date}`);
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

// let websiteStartStamp = "2024-04-30 02:52:09";
// let websiteEndStamp = "2024-04-30 23:19:49";

// let emailStartStamp = "2024-04-30 00:05:00";
// let emailEndStamp = "2024-05-01 00:07:00";

// let whatsappStartStamp = "2024-04-30 00:28:00";
// let whatsappEndStamp = "2024-05-01 00:14:00";

// let websiteIncomingStartStamp = "2024-04-30";
// let websiteIncomingEndStamp = "2024-04-30";

// let apiReplyStartStamp = "2024-04-30 00:08:00";
// let apiReplyEndStamp = "2024-05-01 00:11:00";

// let dtmfStartDate = "2024-04-30";
// let websiteStartStamp = "2024-04-30 23:19:00";
// let websiteEndStamp = "2024-05-01 18:15:00";

// let emailStartStamp = "2024-05-01 00:07:00";
// let emailEndStamp = "2024-05-01 18:15:00";

// let whatsappStartStamp = "2024-05-01 00:14:00";
// let whatsappEndStamp = "2024-05-01 18:15:00";

// let websiteIncomingStartStamp = "2024-05-01";
// let websiteIncomingEndStamp = "2024-05-01";

// let apiReplyStartStamp = "2024-05-01 00:11:00";
// let apiReplyEndStamp = "2024-05-01 18:15:00";

// let dtmfStartDate = "2024-05-01";
