import React from "react";
import Incoming from "./sources/Incoming";

const Insights = () => {
  return (
    <div>
      <Incoming />
    </div>
  );
};

export default Insights;
