import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import moment from 'moment';
import { getIvrServiceForm } from '../../api/ivr';

const GetIvrFormData = () => {
  const [data, setData] = useState([]);

  const getIvrService = async () => {
    try {
      const result = await getIvrServiceForm();
      const formattedResult = result.result.map(item => ({
        ...item,
        createdAt: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')
      }));
      setData(formattedResult);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getIvrService();
  }, []);

  const columns = [
    {
      title: 'Sr. No',
      key: 'index',
      render: (text, record, index) => index + 1,
      className: "font-semibold text-center",
      align: 'center'
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      className: "font-semibold text-center",
      align: 'center'
    },
    {
      title: 'Account',
      dataIndex: 'account',
      key: 'account',
      className: "font-semibold text-center",
      align: 'center'
    },
    {
      title: 'Service',
      dataIndex: 'service',
      key: 'service',
      className: "font-semibold text-center",
      align: 'center'
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      className: "font-semibold text-center",
      align: 'center'
    },
   
  ];

  return (
    <div>
      <Table
        dataSource={data}
        columns={columns}
        scroll={{ y: 400 }}
        pagination={false}
        sticky={true}
        bordered
      />
    </div>
  );
};

export default GetIvrFormData;
  