import React from "react";
import DialerTimeGraph from "./DialerTimeGraph";
import DialerDateGraph from "./DialerDateGraph";

const DialerGraphContainer = ({ filteredData, graphType, CallStatus }) => {
  return (
    <div className="border-2 p-4 m-4 border-dashed rounded-lg">
      <p className="text-center text-4xl">
        {graphType === "TIME" ? "Time Graph" : "Date Graph"}
      </p>
      <div
        style={{
          height: "30rem",
        }}
        class="p-4 my-4 rounded-3xl lg:col-span-3 flex items-center justify-center"
      >
        {graphType === "DATE" ? (
          <DialerDateGraph
            filteredData={filteredData}
            CallStatus={CallStatus}
          />
        ) : (
          <DialerTimeGraph
            filteredData={filteredData}
            CallStatus={CallStatus}
          />
        )}
      </div>
    </div>
  );
};

export default DialerGraphContainer;
