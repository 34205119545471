import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
const Dispo = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
    }
  }, []);
  return (
    <div>
      {/* <iframe
        title="QRT REPORTS"
        src="https://app.powerbi.com/view?r=eyJrIjoiM2U2Y2ZiMTktZmNkYS00ODAxLTgzMDAtMGY4NDcxMzRhMDI0IiwidCI6IjhkYjJiZjNmLTM2ZTgtNDg5Ny1iOWNlLTFjMTcyNGY3NjQyYSJ9&pageName=ReportSection"
        frameborder="0"
        allowFullScreen="true"
        className="w-screen"
        style={{ height: "90vh" }}
      ></iframe> */}
      <iframe
        title="ODOO ERP"
        width="600"
        height="373.5"
        src="https://app.powerbi.com/view?r=eyJrIjoiM2I4MmM4NzUtM2QwMy00YWM4LTljZGItODkyMzA0Nzk0NTQ5IiwidCI6IjhkYjJiZjNmLTM2ZTgtNDg5Ny1iOWNlLTFjMTcyNGY3NjQyYSJ9"
        frameborder="0"
        allowFullScreen="true"
        className="w-screen"
        style={{ height: "90vh" }}
      ></iframe>
    </div>
  );
};

export default Dispo;
