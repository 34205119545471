import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const AirtelTimeGraph = ({ filteredData }) => {
  const [data, setData] = useState([]);

  const fetchHour = (time) => {
    return parseInt(time.split(":")[0], 10);
  };

  const processFilteredData = () => {
    let processedData = [];
    const total = filteredData[0].total
      ? JSON.parse(filteredData[0].total)
      : {};

    Object.keys(total).forEach((hour) => {
      processedData.push({
        name: hour,
        gstTotalCalls: total[hour]["GST"].totalCalls,
        pvtTotalCalls: total[hour]["PVT"].totalCalls,
      });
    });

    setData(processedData);
  };

  useEffect(() => {
    processFilteredData();
  }, [filteredData]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <LineChart width={500} height={500} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Legend />
        <Tooltip />
        <CartesianGrid strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="pvtTotalCalls"
          stroke="#E178C5"
          fill="#E178C5"
        />
        <Line
          type="monotone"
          dataKey="gstTotalCalls"
          stroke="#7743DB"
          fill="#7743DB"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AirtelTimeGraph;
