import client from "./client";

export const exportData = async (obj) => {
  try {
    const { data } = await client.post(`/exportData/export_data`, {
      obj,
    });
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
