// src/LeadsTable.js
import React from "react";
import { Table } from "antd";

const LeadsTable = ({ leads }) => {
  const columns = leads[0]
    ? Object.keys(leads[0]).map((key) => ({
        title: key,
        dataIndex: key,
        key,
        render: (text) => {
          if (key.toLowerCase().includes("time")) {
            return formatDate(text);
          }
          return text;
        }, // Ensure width is auto
      }))
    : [];

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "short", day: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <h2 className="text-xl font-semibold mt-6">Leads</h2>
      <Table
        columns={columns}
        dataSource={leads}
        pagination={{ pageSize: 10 }}
        scroll={{ x: true }}
        rowKey={(record) => record.Contact} // Ensure a unique key for each row
      />
    </div>
  );
};

export default LeadsTable;
