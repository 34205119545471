import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const DialerDateGraph = ({ filteredData, CallStatus }) => {
  const [data, setData] = useState([]);

  // format '2024-03-05T00:00:00.000Z' to "DD/MM/YYYY" format
  function formatDateToDDMMYYYY(dateString) {
    // Create a new Date object from the string
    const date = new Date(dateString);

    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  const getAllSourceTotal = (total, hour) => {
    const pvtAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalCalls
      : 0;
    const pvtMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["PVT"].totalCalls
      : 0;
    const gstAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalCalls
      : 0;
    const gstMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["GST"].totalCalls
      : 0;
    const unAnsCalls = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalCalls
      : 0;
    const unMisCalls = total[hour]["MISSED"]
      ? total[hour]["MISSED"]["UNKNOWN"].totalCalls
      : 0;
    let pvtInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalInterested
      : 0;
    let gstInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalInterested
      : 0;
    let unInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalInterested
      : 0;
    const totalInterested = pvtInt + gstInt + unInt;
    return {
      pvtAnsCalls,
      pvtMisCalls,
      gstAnsCalls,
      gstMisCalls,
      unAnsCalls,
      unMisCalls,
      totalInterested,
    };
  };

  const particularCallStatusAllSourceTotal = (total, hour) => {
    const pvtCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["PVT"].totalCalls
      : 0;
    const gstCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["GST"].totalCalls
      : 0;
    const unCalls = total[hour][CallStatus]
      ? total[hour][CallStatus]["UNKNOWN"].totalCalls
      : 0;
    let pvtInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["PVT"].totalInterested
      : 0;
    let gstInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["GST"].totalInterested
      : 0;
    let unInt = total[hour]["ANSWERED"]
      ? total[hour]["ANSWERED"]["UNKNOWN"].totalInterested
      : 0;
    const totalInterested = pvtInt + gstInt + unInt;
    return {
      pvtCalls,
      gstCalls,
      unCalls,
      totalInterested,
    };
  };

  const calculateTotal = (total) => {
    let totalSourcesCalls = {
      pvtTotalCalls: 0,
      gstTotalCalls: 0,
      unknownTotalCalls: 0,
      totalInterested: 0,
    };

    Object.keys(total).forEach((hour) => {
      if (CallStatus === "ALL") {
        const {
          pvtAnsCalls,
          pvtMisCalls,
          gstAnsCalls,
          gstMisCalls,
          unAnsCalls,
          unMisCalls,
          totalInterested,
        } = getAllSourceTotal(total, hour);

        totalSourcesCalls.pvtTotalCalls += pvtAnsCalls + pvtMisCalls;
        totalSourcesCalls.gstTotalCalls += gstAnsCalls + gstMisCalls;
        totalSourcesCalls.unknownTotalCalls += unAnsCalls + unMisCalls;
        totalSourcesCalls.totalInterested += totalInterested;
      } else {
        const { pvtCalls, gstCalls, unCalls, totalInterested } =
          particularCallStatusAllSourceTotal(total, hour);
        totalSourcesCalls.pvtTotalCalls += pvtCalls;
        totalSourcesCalls.gstTotalCalls += gstCalls;
        totalSourcesCalls.unknownTotalCalls += unCalls;
        totalSourcesCalls.totalInterested += totalInterested;
      }
    });
    return totalSourcesCalls;
  };

  const processFilteredData = () => {
    let processedData = [];

    filteredData.forEach((data) => {
      const total = JSON.parse(data.total);
      const totalSourcesCalls = calculateTotal(total);
      processedData.push({
        name: data.date,
        gstTotalCalls: totalSourcesCalls.gstTotalCalls,
        pvtTotalCalls: totalSourcesCalls.pvtTotalCalls,
        unknownTotalCalls: totalSourcesCalls.unknownTotalCalls,
        totalInterested: totalSourcesCalls.totalInterested,
      });
    });

    const sortedData = processedData.sort((a, b) => {
      const dateA = new Date(a.name.split("/").reverse().join("/"));
      const dateB = new Date(b.name.split("/").reverse().join("/"));
      return dateA - dateB;
    });
    setData(sortedData);
  };

  useEffect(() => {
    processFilteredData();
  }, [filteredData, CallStatus]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <LineChart width={500} height={500} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Legend />
        <Tooltip />
        <CartesianGrid strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="pvtTotalCalls"
          stroke="#E178C5"
          fill="#E178C5"
        />
        <Line
          type="monotone"
          dataKey="gstTotalCalls"
          stroke="#7743DB"
          fill="#7743DB"
        />
        <Line
          type="monotone"
          dataKey="unknownTotalCalls"
          stroke="#36A2EB"
          fill="#36A2EB"
        />
        <Line
          type="monotone"
          dataKey="totalInterested"
          stroke="green"
          fill="green"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default DialerDateGraph;
