import React, { useState, useEffect } from "react";
import { DatePicker, Button, Table } from "antd";
import moment from "moment";
import { getAirtelMissedReport } from "../../api/airtel";

const { RangePicker } = DatePicker;

const MissedCalls = () => {
  const [startDate, setStartDate] = useState(moment().format("DD/MM/YYYY"));
  const [endDate, setEndDate] = useState(moment().format("DD/MM/YYYY"));
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const data = await getAirtelMissedReport(startDate, endDate);

      if (data.success) {
        setData(data.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    setLoading(false);
  };

  return (
    <div className="container mx-auto my-8">
      <div className="flex justify-between mb-4">
        <RangePicker
          defaultValue={[moment(), moment()]}
          onChange={(dates) => {
            if (dates && dates.length === 2) {
              setStartDate(moment(dates[0]).format("DD/MM/YYYY"));
              setEndDate(moment(dates[1]).format("DD/MM/YYYY"));
            }
          }}
        />
        <Button type="primary" onClick={fetchData}>
          Submit
        </Button>
      </div>
      <div className="overflow-x-auto mx-auto flex justify-center">
        <table className="table-auto border-collapse border border-blue-500">
          <thead className="bg-yellow-200">
            <tr>
              <th className="border border-blue-500 px-4 py-2">Sr. no</th>
              <th className="border border-blue-500 px-4 py-2">
                Customer Number
              </th>
              <th className="border border-blue-500 px-4 py-2">Agent Number</th>
              <th className="border border-blue-500 px-4 py-2">Date</th>
              <th className="border border-blue-500 px-4 py-2">Time</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td className="border border-blue-500 px-4 py-2" colSpan="4">
                  Loading...
                </td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={index}>
                  <td className="border border-blue-500 px-4 py-2">
                    {index + 1}
                  </td>
                  <td className="border border-blue-500 px-4 py-2">
                    {item.Customer_Number}
                  </td>
                  <td className="border border-blue-500 px-4 py-2">
                    {item.Agent_Number}
                  </td>
                  <td className="border border-blue-500 px-4 py-2">
                    {item.Date}
                  </td>
                  <td className="border border-blue-500 px-4 py-2">
                    {item.Time}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MissedCalls;
