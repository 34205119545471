import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const AirtelDateGraph = ({ filteredData }) => {
  const [data, setData] = useState([]);

  // format '2024-03-05T00:00:00.000Z' to "DD/MM/YYYY" format
  function formatDateToDDMMYYYY(dateString) {
    // Create a new Date object from the string
    const date = new Date(dateString);

    // Extract day, month, and year components
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-based
    const year = date.getFullYear();

    // Create the formatted date string
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  const calculateTotal = (total) => {
    let totalSourcesCalls = {
      pvtTotalCalls: 0,
      gstTotalCalls: 0,
    };
    Object.keys(total).forEach((hour) => {
      totalSourcesCalls.pvtTotalCalls += total[hour]["PVT"].totalCalls;
      totalSourcesCalls.gstTotalCalls += total[hour]["GST"].totalCalls;
    });
    return totalSourcesCalls;
  };

  const processFilteredData = () => {
    let processedData = [];

    filteredData.forEach((data) => {
      const summary = JSON.parse(data.summary);
      const total = data.total ? JSON.parse(data.total) : {};
      const totalSourcesCalls = calculateTotal(total);
      processedData.push({
        name: data.date,
        gstTotalCalls: totalSourcesCalls.gstTotalCalls,
        pvtTotalCalls: totalSourcesCalls.pvtTotalCalls,
      });
    });

    const sortedData = processedData.sort((a, b) => {
      const dateA = new Date(a.name.split("/").reverse().join("/"));
      const dateB = new Date(b.name.split("/").reverse().join("/"));
      return dateA - dateB;
    });
    setData(sortedData);
  };

  useEffect(() => {
    processFilteredData();
  }, [filteredData]);

  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <LineChart width={500} height={500} data={data}>
        <XAxis dataKey="name" />
        <YAxis />
        <Legend />
        <Tooltip />
        <CartesianGrid strokeDasharray="5 5" />
        <Line
          type="monotone"
          dataKey="pvtTotalCalls"
          stroke="#E178C5"
          fill="#E178C5"
        />
        <Line
          type="monotone"
          dataKey="gstTotalCalls"
          stroke="#7743DB"
          fill="#7743DB"
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AirtelDateGraph;
