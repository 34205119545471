import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined } from "@ant-design/icons";
import { addReport } from "../../api/report";
import toast, { Toaster } from "react-hot-toast";
import { CSVLink } from "react-csv";

function Dialer_report({ selectedOption }) {
  const [dataSource, setDataSource] = useState([]);
  const [arr, setArr] = useState([]);
  const [saving, setSaving] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState([]);

  function getKeyForElement(element) {
    const lowerElement = element.toLowerCase();
    for (const key in mappedKeys) {
      const lowerKeys = mappedKeys[key].map((item) => item.toLowerCase());
      if (lowerKeys.includes(lowerElement)) {
        return key;
      }
    }
    return null; // If no key found for the element
  }
  const mappedKeys = {
    // customerNumber: ["Customer Number", "Customer Number"],
    ["Disposition Name"]: [
      "Agent Disposition",
      "Disposition Name",
      "status_name",
    ],
    ["Total Call Duration (HH:MM:SS)"]: [
      "Customer Talk Duration",
      "Total Call Duration (HH:MM:SS)",
      "length_in_sec",
    ],
    ["Campaign Name"]: ["Campaign Name", "Campaign Name", "campaign_name"],
    ["Connected to Agent"]: ["Connected to Agent", "Agent Name", "full_name"],
    // ["Sub Disposition Code"]: ["Sub Disposition Code"],
  };
  // Array of specific dispositions
  const specificDispositions = [
    "call rejected",
    "channel issue",
    "issue with receiver network",
    "noans",
    "network issue",
    "invalid dialing",
    "lead being called",
    "not reachable",
    "number changed",
    "system failure",
    "unallocated number",
    "sos",
  ];

  const header = [
    "Busy",
    "Call Back",
    // "Interested",
    "INTRO",
    "Prospect",
    "Language Issue",
    "Need Update",
    "not interested",
    "not pickup",
    "300 sec",
    "DND",
    "scrap",
    "Undisposed",
  ];

  // Function to check if a disposition is present in the array
  const isSpecificDisposition = (disposition) => {
    return specificDispositions.includes(disposition);
  };

  const handleSave = async () => {
    try {
      const jsonData = JSON.stringify(arr);

      const data = await addReport(jsonData, selectedOption);
      if (data.success) {
        toast.success(data.message);
      } else {
        toast.error(data.error);
      }
    } catch (error) {
      console.error("Error while saving:", error);
      toast.error("Error occurred while saving the report");
    }
  };

  // Function to convert duration to seconds
  function durationToSeconds(duration) {
    const [hours, minutes, seconds] = duration.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  async function readAndParseFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = async (event) => {
        try {
          const csvText = event.target.result;
          const parsedData = await parseCSVData(csvText);
          resolve(parsedData);
        } catch (error) {
          reject(error);
        }
      };

      reader.onerror = (event) => {
        reject(event.target.error);
      };

      reader.readAsText(file);
    });
  }

  function parseCSVData(csvText) {
    return new Promise((resolve, reject) => {
      Papa.parse(csvText, {
        header: true,
        complete: (results) => {
          let framedData = [];
          let keys = [];
          Object.keys(results.data[0]).map((key) => {
            keys.push({ mappedKey: getKeyForElement(key), originalKey: key });
          });
          results.data.map((data) => {
            let obj = {};
            keys.forEach(({ originalKey, mappedKey }) => {
              obj[mappedKey] = data[originalKey];
            });
            framedData.push(obj);
          });

          resolve(framedData);
        },
        error: (error) => {
          reject(error);
        },
      });
    });
  }

  const captureAndDownload = () => {
    const element = document.getElementById("income_tax_report"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `Dialer-Report_${moment().format(
        "YYYY-MM-DD HH:mm:ss"
      )}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const parseFileData = async (files) => {
    try {
      const dataArray = await Promise.all(
        files.map(async (file) => {
          return await readAndParseFile(file);
        })
      );
      const mergedArray = [].concat(...dataArray); // or const mergedArray = dataArray.flat();
      return mergedArray;
    } catch (error) {
      throw error;
    }
  };

  // Function to handle file input change
  const handleFileInputChange = async (event) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    const newSelectedFiles = [...selectedFiles, files[0]];
    const overallData = await parseFileData(newSelectedFiles);
    setDataSource(overallData);
    setSelectedFiles(newSelectedFiles);
  };

  const processData = () => {
    const report = {};

    dataSource.forEach((row) => {
      if (!row["Disposition Name"] || !row["Campaign Name"]) return;

      let disposition = row["Disposition Name"].toLocaleLowerCase();
      const team = row["Campaign Name"].toLocaleLowerCase();

      if (disposition === "do not call") disposition = "dnd";
      if (disposition === "not interesred") disposition = "not interested";

      const duration = row["Total Call Duration (HH:MM:SS)"]
        ? durationToSeconds(row["Total Call Duration (HH:MM:SS)"])
        : 0;

      // if (disposition !== "interested" && duration >= 300) {
      if (
        disposition !== "intro" &&
        disposition !== "prospect" &&
        duration >= 300
      ) {
        if (!report["300 sec"]) {
          report["300 sec"] = {
            ["income tax"]: 0,
            ["baroda income tax"]: 0,
            ["delhi income tax"]: 0,
            ["chennai income tax"]: 0,
          };
        }
        if (duration >= 300) {
          report["300 sec"][team] += 1;
        }
      }

      if (disposition && team) {
        if (!report[disposition]) {
          report[disposition] = {};
        }

        report[disposition][team] = (report[disposition][team] || 0) + 1;

        // if (disposition === "interested") {
        //   const subDisposition =
        //     row["Sub Disposition Code"]?.toLocaleLowerCase();
        //   if (subDisposition === "intro") {
        //     if (!report[subDisposition]) {
        //       report[subDisposition] = {};
        //     }
        //     report[subDisposition][team] =
        //       (report[subDisposition][team] || 0) + 1;
        //   }
        // }
      }
    });

    Object.keys(report).forEach((key) => {
      if (isSpecificDisposition(key)) {
        report["not pickup"]["income tax"] += parseInt(
          report[key]["income tax"] || 0
        );
        report["not pickup"]["baroda income tax"] += parseInt(
          report[key]["baroda income tax"] || 0
        );
        report["not pickup"]["delhi income tax"] += parseInt(
          report[key]["delhi income tax"] || 0
        );
      }
      if (key === "wrong number") {
        report["scrap"]["income tax"] += parseInt(
          report[key]["income tax"] || 0
        );
        report["scrap"]["baroda income tax"] += parseInt(
          report[key]["baroda income tax"] || 0
        );
        report["scrap"]["delhi income tax"] += parseInt(
          report[key]["delhi income tax"] || 0
        );
        report["scrap"]["chennai income tax"] += parseInt(
          report[key]["chennai income tax"] || 0
        );
      }

      if (key === "receiver is busy") {
        report["busy"]["income tax"] += parseInt(
          report[key]["income tax"] || 0
        );
        report["busy"]["baroda income tax"] += parseInt(
          report[key]["baroda income tax"] || 0
        );
        report["busy"]["delhi income tax"] += parseInt(
          report[key]["delhi income tax"] || 0
        );
        report["busy"]["chennai income tax"] += parseInt(
          report[key]["chennai income tax"] || 0
        );
      }

      if (key === "do not call") {
        report["dnd"]["income tax"] += parseInt(report[key]["income tax"] || 0);
        report["dnd"]["baroda income tax"] += parseInt(
          report[key]["baroda income tax"] || 0
        );
        report["dnd"]["delhi income tax"] += parseInt(
          report[key]["delhi income tax"] || 0
        );
        report["dnd"]["chennai income tax"] += parseInt(
          report[key]["chennai income tax"] || 0
        );
      }
    });

    // Adding the new sub dispositions to the existing header array
    // const extendedHeader = [...header, "Intro"];

    let arr = [];

    header.forEach((key) => {
      key = key.toLocaleLowerCase();
      const teamAValue =
        report[key] && report[key]["income tax"]
          ? report[key]["income tax"]
          : 0;
      const teamBValue =
        report[key] && report[key]["baroda income tax"]
          ? report[key]["baroda income tax"]
          : 0;
      const teamFranceValue =
        report[key] && report[key]["delhi income tax"]
          ? report[key]["delhi income tax"]
          : 0;
      const chennaiTeamValue =
        report[key] && report[key]["chennai income tax"]
          ? report[key]["chennai income tax"]
          : 0;

      arr.push({
        header: key,
        "income tax": teamAValue,
        "baroda income tax": teamBValue,
        "delhi income tax": teamFranceValue,
        "chennai income tax": chennaiTeamValue,
        "grand total":
          teamAValue + teamBValue + teamFranceValue + chennaiTeamValue,
      });
    });

    const grandTotal = {
      header: "grand total",
      "income tax": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["income tax"] || 0);
        }
        return total;
      }, 0),
      "baroda income tax": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["baroda income tax"] || 0);
        }
        return total;
      }, 0),
      "delhi income tax": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["delhi income tax"] || 0);
        }
        return total;
      }, 0),
      "chennai income tax": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["chennai income tax"] || 0);
        }
        return total;
      }, 0),
      "grand total": arr.reduce((total, item) => {
        if (item.header !== "300 sec") {
          return total + (item["grand total"] || 0);
        }
        return total;
      }, 0),
    };
    arr.push(grandTotal);

    arr.map((row) => {
      row.header = row.header.toUpperCase();
    });

    const result = {};

    arr.forEach((item) => {
      const header = item.header;
      if (!result[header]) {
        result[header] = {
          ahmedabadTeam: 0,
          chennai: 0,
          grandTotal: 0,
        };
      }
      result[header].ahmedabadTeam +=
        item["baroda income tax"] +
        item["delhi income tax"] +
        item["income tax"];
      result[header].chennai += item["chennai income tax"];
      result[header].grandTotal += item["grand total"];
    });

    setArr(result);
    console.log(result);
    handleSave();
  };
  const handleDownload = () => {
    document.getElementById("csv-link").click();
  };

  const handleGenerateReport = () => {
    // Function to generate the report when the button is clicked
    processData();
  };

  const arrAsArray = Object.keys(arr).map((header) => ({
    Disposition: header,
    "Ahmedabad Team": arr[header].ahmedabadTeam,
    Chennai: arr[header].chennai,
    "Grand Total": arr[header].grandTotal,
  }));

  return (
    <div className="m-5">
      <h1 className="font-semibold">Excel to CSV Converter : </h1>
      <form className="flex flex-row w-fit gap-x-3">
        <input type="file" multiple onChange={handleFileInputChange} />
        <button
          type="button"
          onClick={handleGenerateReport}
          className="border-black cursor-pointer rounded-sm font-semibold border-2 p-1 text-center"
        >
          Generate Report
        </button>
        {arr && (
          <>
            <button
              type="button"
              onClick={handleDownload}
              className="border-black cursor-pointer rounde d-sm font-semibold border-2 p-1 text-center"
            >
              {" "}
              Download CSV
            </button>
            <div
              onClick={captureAndDownload}
              className="border-black cursor-pointer rounded-sm  font-semibold border-2  p-1 text-center"
            >
              <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
              Download PNG
            </div>
          </>
        )}
      </form>
      <div>
        {selectedFiles.length > 0 && (
          <div className="">
            <h2 className="text-lg font-semibold mb-2">Selected Files:</h2>
            <ul>
              {selectedFiles.map((file, index) => (
                <li key={index} className="flex items-center mb-1">
                  <span className="mr-2">{file.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
      <CSVLink
        id="csv-link"
        data={arrAsArray}
        filename={`income_tax_report.csv`}
      />

      {arr && (
        <div className="flex justify-center mt-5">
          {console.log(arr)}
          <table
            className="border-collapse border font-bold "
            id="income_tax_report"
          >
            <thead>
              <tr>
                <th className="border-2 border-black px-3 text-center bg-[#FEFF00] ">
                  Disposition
                </th>
                <th className="border-2 border-black px-3 text-center bg-[#FEFF00]">
                  Ahmedabad Team
                </th>
                <th className="border-2 border-black px-3 text-center bg-[#FEFF00]">
                  Chennai
                </th>
                <th className="border-2 border-black px-3 text-center bg-[#FEFF00]">
                  Grand Total
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(arr).map((header) => (
                <tr key={header} className="border ">
                  <td
                    className={`border-2 border-black px-3 text-center ${
                      header === "GRAND TOTAL" ? "bg-[#FEFF00]" : ""
                    } `}
                  >
                    {header}
                  </td>
                  <td
                    className={`border-2 border-black px-3 text-center ${
                      header === "GRAND TOTAL" ? "bg-[#FEFF00]" : ""
                    } `}
                  >
                    {arr[header].ahmedabadTeam}
                  </td>
                  <td
                    className={`border-2 border-black px-3 text-center ${
                      header === "GRAND TOTAL" ? "bg-[#FEFF00]" : ""
                    } `}
                  >
                    {arr[header].chennai}
                  </td>
                  <td
                    className={`border-2 border-black px-3 text-center ${
                      header === "GRAND TOTAL" ? "bg-[#FEFF00]" : ""
                    } `}
                  >
                    {arr[header].grandTotal}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Dialer_report;
