import React, { useEffect, useState, useRef } from "react";
import { getAirtelAggregatedData } from "../../../api/airtel";

import { Modal, Skeleton } from "antd";
// Table Utils
import { Table, Input, Button, Space, Spin, DatePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import toast from "react-hot-toast";
import AirtelReportDownload from "./AirtelReportDownload";

import EmployeeGraph from "../Graph/EmployeeGraph";
import AirtelGraphContainer from "../Graph/Airtel/AirtelGraphContainer";
import Agent_Report from "./Agent_Report";
import {
  calculateAverageForSource,
  calculateSourceTotal,
  updateGrandTotal,
  updateTotalCallsAndDurationForAll,
  updateTotalCallsAndDurationForAllSources,
  updateTotalCallsAndDurationForSpecificSource,
  updateTotalsForAllSources,
  updateTotalsForAllSourcesAndCallTypes,
} from "./airtelUtils";

// Array of source options
const sourceOptions = [
  { value: "GST", label: "GST" },
  { value: "PVT", label: "PVT" },
  { value: "ALL", label: "ALL" },
];

const Agent_Customer_Talk_Time_Report = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAgentReportOpen, setIsAgentReportOpen] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSource, setSelectedSource] = useState("GST");

  const [filteredData, setFilteredData] = useState([]);
  const [dateWiseAggregatedData, setDateWiseAggregatedData] = useState([]);

  const [total, setTotal] = useState({
    PVT: {
      totalCalls: 0,
      totalDuration: 0,
    },
    GST: {
      totalCalls: 0,
      totalDuration: 0,
    },
    ["DND"]: {
      totalCalls: 0,
      totalDuration: 0,
    },
  });

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [graphType, setGraphType] = useState("DATE");

  const [filterLoading, setFilterLoading] = useState(false);

  // For suearching logic in the ANTD Table
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function secondsToHMS(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const columns = [
    {
      title: "Agent Number",
      dataIndex: "Agent_Number",
      key: "Agent_Number",
      width: 200,
      className: "font-semibold",
      ...getColumnSearchProps("Agent_Number"),
    },
    {
      title: "Agent Name",
      dataIndex: "Agent_Name",
      key: "Agent_Name",
      className: "font-semibold",
      ...getColumnSearchProps("Agent_Name"),
    },
    {
      title: "INBOUND",
      dataIndex: "INBOUND",
      key: "INBOUND",
      sorter: (a, b) => {
        if (selectedSource === "ALL") {
          return (
            a["PVT"]["INBOUND"].totalCalls +
            a["GST"]["INBOUND"].totalCalls -
            (b["PVT"]["INBOUND"].totalCalls + a["GST"]["INBOUND"].totalCalls)
          );
        } else {
          return (
            a[selectedSource]["INBOUND"].totalCalls -
            b[selectedSource]["INBOUND"].totalCalls
          );
        }
      },
      render: (text, record) => {
        let avgCallDuration;
        if (selectedSource === "ALL") {
          avgCallDuration = Math.ceil(
            (record["PVT"]["INBOUND"].totalDuration +
              record["GST"]["INBOUND"].totalDuration) /
              (record["PVT"]["INBOUND"].totalCalls +
                record["GST"]["INBOUND"].totalCalls)
          );
          avgCallDuration = isNaN(avgCallDuration) ? 0 : avgCallDuration;
        } else {
          avgCallDuration = Math.ceil(
            record[selectedSource]["INBOUND"].totalDuration /
              record[selectedSource]["INBOUND"].totalCalls
          );
          avgCallDuration = isNaN(avgCallDuration) ? 0 : avgCallDuration;
        }
        const avgDuration = secondsToHMS(avgCallDuration);
        let totalINBOUNDCalls = 0;
        let totalINBOUNDDuration = 0;
        let totalCalls = 0;
        let totalDuration = 0;
        if (selectedSource === "ALL") {
          totalINBOUNDCalls =
            record["PVT"]["INBOUND"].totalCalls +
            record["GST"]["INBOUND"].totalCalls;

          totalINBOUNDDuration =
            record["PVT"]["INBOUND"].totalDuration +
            record["GST"]["INBOUND"].totalDuration;

          totalCalls = record["PVT"].totalCalls + record["GST"].totalCalls;
          totalDuration =
            record["PVT"].totalDuration + record["GST"].totalDuration;
        } else {
          totalINBOUNDCalls = record[selectedSource]["INBOUND"].totalCalls;
          totalINBOUNDDuration =
            record[selectedSource]["INBOUND"].totalDuration;
          totalCalls = record[selectedSource].totalCalls;
          totalDuration = record[selectedSource].totalDuration;
        }
        const totalCallsPercentage = (
          (totalINBOUNDCalls / totalCalls) *
          100
        ).toFixed(2);
        const totalDurationPercentage = (
          (totalINBOUNDDuration / totalDuration) *
          100
        ).toFixed(2);
        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black  font-bold bg-[#90D150] text-center">
                  <span className="flex-col">
                    <p>{totalINBOUNDCalls}</p>
                    <p>
                      [{isNaN(totalCallsPercentage) ? 0 : totalCallsPercentage}{" "}
                      %]
                    </p>
                  </span>
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  <span className="flex-col">
                    <p>{secondsToHMS(totalINBOUNDDuration)}</p>
                    <p>
                      [
                      {isNaN(totalDurationPercentage)
                        ? 0
                        : totalDurationPercentage}{" "}
                      %]
                    </p>
                  </span>
                </td>
                <td className="border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "OUTBOUND",
      dataIndex: "OUTBOUND",
      key: "OUTBOUND",
      sorter: (a, b) => {
        if (selectedSource === "ALL") {
          return (
            a["PVT"]["OUTBOUND"].totalCalls +
            a["GST"]["OUTBOUND"].totalCalls -
            (b["PVT"]["OUTBOUND"].totalCalls + a["GST"]["OUTBOUND"].totalCalls)
          );
        } else {
          return (
            a[selectedSource]["OUTBOUND"].totalCalls -
            b[selectedSource]["OUTBOUND"].totalCalls
          );
        }
      },
      render: (text, record) => {
        let avgCallDuration;
        if (selectedSource === "ALL") {
          avgCallDuration = Math.ceil(
            (record["PVT"]["OUTBOUND"].totalDuration +
              record["GST"]["OUTBOUND"].totalDuration) /
              (record["PVT"]["OUTBOUND"].totalCalls +
                record["GST"]["OUTBOUND"].totalCalls)
          );
          avgCallDuration = isNaN(avgCallDuration) ? 0 : avgCallDuration;
        } else {
          avgCallDuration = Math.ceil(
            record[selectedSource]["OUTBOUND"].totalDuration /
              record[selectedSource]["OUTBOUND"].totalCalls
          );
          avgCallDuration = isNaN(avgCallDuration) ? 0 : avgCallDuration;
        }
        const avgDuration = secondsToHMS(avgCallDuration);
        let totalOUTBOUNDCalls = 0;
        let totalOUTBOUNDDuration = 0;
        let totalCalls = 0;
        let totalDuration = 0;
        if (selectedSource === "ALL") {
          totalOUTBOUNDCalls =
            record["PVT"]["OUTBOUND"].totalCalls +
            record["GST"]["OUTBOUND"].totalCalls;

          totalOUTBOUNDDuration =
            record["PVT"]["OUTBOUND"].totalDuration +
            record["GST"]["OUTBOUND"].totalDuration;

          totalCalls = record["PVT"].totalCalls + record["GST"].totalCalls;
          totalDuration =
            record["PVT"].totalDuration + record["GST"].totalDuration;
        } else {
          totalOUTBOUNDCalls = record[selectedSource]["OUTBOUND"].totalCalls;
          totalOUTBOUNDDuration =
            record[selectedSource]["OUTBOUND"].totalDuration;
          totalCalls = record[selectedSource].totalCalls;
          totalDuration = record[selectedSource].totalDuration;
        }
        const totalCallsPercentage = (
          (totalOUTBOUNDCalls / totalCalls) *
          100
        ).toFixed(2);
        const totalDurationPercentage = (
          (totalOUTBOUNDDuration / totalDuration) *
          100
        ).toFixed(2);
        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black  font-bold bg-[#90D150] text-center">
                  <span className="flex-col">
                    <p>{totalOUTBOUNDCalls} </p>
                    <p>
                      [{isNaN(totalCallsPercentage) ? 0 : totalCallsPercentage}
                      %]
                    </p>
                  </span>
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  <span className="flex-col">
                    <p>{secondsToHMS(totalOUTBOUNDDuration)}</p>
                    <p>
                      {" "}
                      [
                      {isNaN(totalDurationPercentage)
                        ? 0
                        : totalDurationPercentage}{" "}
                      %]{" "}
                    </p>
                  </span>
                </td>
                <td className="border-2 border-black  bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
    },
    {
      title: "TOTAL",
      dataIndex: "totalCalls",
      key: "totalCalls",
      sorter: (a, b) => {
        if (selectedSource === "ALL") {
          return a.totalCalls - b.totalCalls;
        } else {
          return a[selectedSource].totalCalls - b[selectedSource].totalCalls;
        }
      },
      render: (text, record) => {
        let totalDuration = 0;
        let totalCalls = 0;
        if (selectedSource === "ALL") {
          totalDuration = record.totalDuration;
          totalCalls = record.totalCalls;
        } else {
          totalDuration = record[selectedSource].totalDuration;
          totalCalls = record[selectedSource].totalCalls;
        }

        let avgCallDuration = Math.ceil(totalDuration / totalCalls);
        avgCallDuration = isNaN(avgCallDuration) ? 0 : avgCallDuration;
        const avgDuration = secondsToHMS(avgCallDuration);
        totalDuration = secondsToHMS(totalDuration);
        return (
          <table>
            <tbody>
              <tr className="bg-slate-200">
                <th className="border border-gray-300">TOTAL CALLS</th>
                <th className="border border-gray-300">TOTAL DURATION</th>
                <th className="border border-gray-300">AVG. CALL DURATION</th>
              </tr>
              <tr>
                <td className="border-2 border-black bg-[#90D150] font-bold text-center">
                  {totalCalls}
                </td>
                <td className="border-y-2 border-black bg-[#FFD866] font-bold text-center">
                  {totalDuration}
                </td>
                <td className="border-2 border-black bg-[#8EA9DC] font-bold text-center">
                  {avgDuration}
                </td>
              </tr>
            </tbody>
          </table>
        );
      },
      //   sorter: (a, b) => a.Outbound_Calls - b.Outbound_Calls,
    },
  ];

  const handleDownload = () => {
    if (!startDate || !endDate) {
      toast.error("Please select both start and end dates");
      return;
    }
    document.getElementById("csv-link").click();
  };

  function viewCSV() {
    showModal();
  }
  function viewAgentReport() {
    showAgentReport();
  }

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleAgentReportCancel = () => {
    setIsAgentReportOpen(false);
  };
  const showAgentReport = () => {
    setIsAgentReportOpen(true);
  };
  const handleAgentReportOk = () => {
    setIsAgentReportOpen(false);
  };

  const handleChange = (event) => {
    setSelectedSource(event.target.value);
  };

  function isDateBetween(rowDateStr, startDateStr, endDateStr) {
    const parseDate = (dateStr) => {
      const [day, month, year] = dateStr.split("/");
      return new Date(`${year}-${month}-${day}`);
    };

    const rowDate = parseDate(rowDateStr);
    const startDate = parseDate(startDateStr);
    const endDate = parseDate(endDateStr);

    return rowDate >= startDate && rowDate <= endDate;
  }

  const parseDate = (dateStr) => {
    const [day, month, year] = dateStr.split("/");
    return new Date(`${year}-${month}-${day}`);
  };
  const rangeWiseFiltering = (startDate, endDate) => {
    setFilterLoading(true);
    let filteredAggregatedData = [];
    if (!startDate || !endDate) {
      filteredAggregatedData = dateWiseAggregatedData;
      processAggregatedData(filteredAggregatedData);
      setGraphType("DATE");
    } else if (startDate === endDate) {
      dateWiseAggregatedData.forEach((row) => {
        if (row.date === startDate) {
          filteredAggregatedData.push({
            ...row,
          });
        }
      });
      processAggregatedData(filteredAggregatedData);
      setGraphType("TIME");
    } else {
      const start = parseDate(startDate);
      const end = parseDate(endDate);
      if (isNaN(start) || isNaN(end)) {
        throw new Error("Invalid start or end date");
      }
      if (start > end) {
        toast.error("Start date should be less than end date");
        return;
      }
      dateWiseAggregatedData.forEach((row) => {
        if (isDateBetween(row.date, startDate, endDate)) {
          filteredAggregatedData.push({
            ...row,
          });
        }
      });
      processAggregatedData(filteredAggregatedData);
      setGraphType("DATE");
    }
    setFilterLoading(false);
  };

  const clearFilter = () => {
    setStartDate(null);
    setEndDate(null);
    rangeWiseFiltering(null, null);
  };
  // Main function to add call details
  const addCallDetails = (
    processedAggregatedData,
    Agent_Name,
    Agent_Call_Data
  ) => {
    updateTotalCallsAndDurationForSpecificSource(
      processedAggregatedData,
      Agent_Name,
      Agent_Call_Data
    );
    updateTotalCallsAndDurationForAllSources(
      processedAggregatedData,
      Agent_Name,
      Agent_Call_Data
    );
    updateTotalCallsAndDurationForAll(
      processedAggregatedData,
      Agent_Name,
      Agent_Call_Data
    );
  };

  // const calculateTotal = (total) => {
  //   let totalSourcesCalls = {
  //     ["PVT"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       totalCalls: 0,
  //       totalDuration: 0,
  //       avgCallDuration: 0,
  //     },
  //     ["GST"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       totalCalls: 0,
  //       totalDuration: 0,
  //       avgCallDuration: 0,
  //     },
  //     ["UNKNOWN"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       totalCalls: 0,
  //       totalDuration: 0,
  //       avgCallDuration: 0,
  //     },
  //     totalCalls: 0,
  //     totalDuration: 0,
  //     avgCallDuration: 0,
  //   };
  //   Object.keys(total).forEach((hour) => {
  //     // total calls calulation based on source
  //     totalSourcesCalls["PVT"].totalCalls += total[hour]["PVT"].totalCalls;
  //     totalSourcesCalls["GST"].totalCalls += total[hour]["GST"].totalCalls;
  //     // total duration calulation based on source
  //     totalSourcesCalls["PVT"].totalDuration +=
  //       total[hour]["PVT"].totalDuration;
  //     totalSourcesCalls["GST"].totalDuration +=
  //       total[hour]["GST"].totalDuration;

  //     // total calls calulation based on source and call_tyoe
  //     totalSourcesCalls["PVT"]["OUTBOUND"].totalCalls +=
  //       total[hour]["PVT"]["OUTBOUND"].totalCalls;
  //     totalSourcesCalls["PVT"]["INBOUND"].totalCalls +=
  //       total[hour]["PVT"]["INBOUND"].totalCalls;
  //     totalSourcesCalls["GST"]["OUTBOUND"].totalCalls +=
  //       total[hour]["GST"]["OUTBOUND"].totalCalls;
  //     totalSourcesCalls["GST"]["INBOUND"].totalCalls +=
  //       total[hour]["GST"]["INBOUND"].totalCalls;

  //     // total duration calulation based on source
  //     totalSourcesCalls["PVT"]["OUTBOUND"].totalDuration +=
  //       total[hour]["PVT"]["OUTBOUND"].totalDuration;

  //     totalSourcesCalls["PVT"]["INBOUND"].totalDuration +=
  //       total[hour]["PVT"]["INBOUND"].totalDuration;

  //     totalSourcesCalls["GST"]["OUTBOUND"].totalDuration +=
  //       total[hour]["GST"]["OUTBOUND"].totalDuration;

  //     totalSourcesCalls["GST"]["INBOUND"].totalDuration +=
  //       total[hour]["GST"]["INBOUND"].totalDuration;

  //     const totalCalls =
  //       total[hour]["PVT"].totalCalls + total[hour]["GST"].totalCalls;
  //     const totalDuration =
  //       total[hour]["PVT"].totalDuration + total[hour]["GST"].totalDuration;

  //     // total calls and duration calulation
  //     totalSourcesCalls["totalCalls"] += totalCalls;
  //     totalSourcesCalls["totalDuration"] += totalDuration;
  //   });
  //   return totalSourcesCalls;
  // };

  // const addTotals = (grandTotal, totalSourcesCalls) => {
  //   // total calls calculations source and call type wise
  //   grandTotal["PVT"]["OUTBOUND"].totalCalls +=
  //     totalSourcesCalls["PVT"]["OUTBOUND"].totalCalls;

  //   grandTotal["PVT"]["INBOUND"].totalCalls +=
  //     totalSourcesCalls["PVT"]["INBOUND"].totalCalls;

  //   grandTotal["GST"]["OUTBOUND"].totalCalls +=
  //     totalSourcesCalls["GST"]["OUTBOUND"].totalCalls;

  //   grandTotal["GST"]["INBOUND"].totalCalls +=
  //     totalSourcesCalls["GST"]["INBOUND"].totalCalls;

  //   grandTotal["UNKNOWN"]["OUTBOUND"].totalCalls +=
  //     totalSourcesCalls["GST"]["OUTBOUND"].totalCalls;

  //   grandTotal["UNKNOWN"]["INBOUND"].totalCalls +=
  //     totalSourcesCalls["UNKNOWN"]["INBOUND"].totalCalls;

  //   // total duration calculations source and call type wise
  //   grandTotal["PVT"]["OUTBOUND"].totalDuration +=
  //     totalSourcesCalls["PVT"]["OUTBOUND"].totalDuration;

  //   grandTotal["PVT"]["INBOUND"].totalDuration +=
  //     totalSourcesCalls["PVT"]["INBOUND"].totalDuration;

  //   grandTotal["GST"]["OUTBOUND"].totalDuration +=
  //     totalSourcesCalls["GST"]["OUTBOUND"].totalDuration;

  //   grandTotal["GST"]["INBOUND"].totalDuration +=
  //     totalSourcesCalls["GST"]["INBOUND"].totalDuration;

  //   grandTotal["UNKNOWN"]["OUTBOUND"].totalDuration +=
  //     totalSourcesCalls["UNKNOWN"]["OUTBOUND"].totalDuration;

  //   grandTotal["UNKNOWN"]["INBOUND"].totalDuration +=
  //     totalSourcesCalls["UNKNOWN"]["INBOUND"].totalDuration;

  //   // total calls caluclation based on source
  //   grandTotal["PVT"].totalCalls += totalSourcesCalls["PVT"].totalCalls;
  //   grandTotal["GST"].totalCalls += totalSourcesCalls["GST"].totalCalls;
  //   grandTotal["UNKNOWN"].totalCalls += totalSourcesCalls["UNKNOWN"].totalCalls;

  //   // total duration calulation based on source
  //   grandTotal["PVT"].totalDuration += totalSourcesCalls["PVT"].totalDuration;
  //   grandTotal["GST"].totalDuration += totalSourcesCalls["GST"].totalDuration;
  //   grandTotal["UNKNOWN"].totalDuration +=
  //     totalSourcesCalls["UNKNOWN"].totalDuration;

  //   // total calls and duration caluclation
  //   grandTotal.totalCalls += totalSourcesCalls["PVT"].totalCalls;
  //   grandTotal.totalCalls += totalSourcesCalls["GST"].totalCalls;
  //   grandTotal.totalCalls += totalSourcesCalls["UNKNOWN"].totalCalls;

  //   grandTotal.totalDuration += totalSourcesCalls["PVT"].totalDuration;
  //   grandTotal.totalDuration += totalSourcesCalls["GST"].totalDuration;
  //   grandTotal.totalDuration += totalSourcesCalls["UNKNOWN"].totalDuration;
  // };

  // Function to calculate total calls and total duration for all sources combined
  const calculateTotal = (hourlyData) => {
    let totalSourcesCalls = {
      ["PVT"]: calculateSourceTotal(hourlyData, "PVT"),
      ["GST"]: calculateSourceTotal(hourlyData, "GST"),
      ["UNKNOWN"]: calculateSourceTotal(hourlyData, "UNKNOWN"),
      totalCalls: 0,
      totalDuration: 0,
      avgCallDuration: 0, // Placeholder for average call duration calculation
    };

    Object.keys(hourlyData).forEach((hour) => {
      // Calculate total calls and total duration for all sources combined
      totalSourcesCalls.totalCalls +=
        hourlyData[hour]["PVT"].totalCalls + hourlyData[hour]["GST"].totalCalls;
      totalSourcesCalls.totalDuration +=
        hourlyData[hour]["PVT"].totalDuration +
        hourlyData[hour]["GST"].totalDuration;
    });

    return totalSourcesCalls;
  };

  const addTotals = (grandTotal, totalSourcesCalls) => {
    updateTotalsForAllSourcesAndCallTypes(grandTotal, totalSourcesCalls);
    updateTotalsForAllSources(grandTotal, totalSourcesCalls);
    updateGrandTotal(grandTotal, totalSourcesCalls);
  };

  // const calculateAverages = (grandTotal) => {
  //   // AVERAGE CALCULATION BASED ON SOURCE AND CALL TYPE
  //   grandTotal["PVT"]["OUTBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["PVT"]["OUTBOUND"].totalDuration /
  //       grandTotal["PVT"]["OUTBOUND"].totalCalls
  //   );
  //   grandTotal["PVT"]["INBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["PVT"]["INBOUND"].totalDuration /
  //       grandTotal["PVT"]["INBOUND"].totalCalls
  //   );

  //   grandTotal["GST"]["OUTBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["GST"]["OUTBOUND"].totalDuration /
  //       grandTotal["GST"]["OUTBOUND"].totalCalls
  //   );
  //   grandTotal["GST"]["INBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["GST"]["INBOUND"].totalDuration /
  //       grandTotal["GST"]["INBOUND"].totalCalls
  //   );

  //   grandTotal["UNKNOWN"]["OUTBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["UNKNOWN"]["OUTBOUND"].totalDuration /
  //       grandTotal["UNKNOWN"]["OUTBOUND"].totalCalls
  //   );
  //   grandTotal["UNKNOWN"]["INBOUND"].avgCallDuration = Math.ceil(
  //     grandTotal["UNKNOWN"]["INBOUND"].totalDuration /
  //       grandTotal["UNKNOWN"]["INBOUND"].totalCalls
  //   );

  //   // AVERAGE CALCULATION BASED IN THE SOURCE
  //   grandTotal["GST"].avgCallDuration = Math.ceil(
  //     grandTotal["GST"].totalDuration / grandTotal["GST"].totalCalls
  //   );
  //   grandTotal["PVT"].avgCallDuration = Math.ceil(
  //     grandTotal["PVT"].totalDuration / grandTotal["GST"].totalCalls
  //   );
  //   grandTotal["UNKNOWN"].avgCallDuration = Math.ceil(
  //     grandTotal["UNKNOWN"].totalDuration / grandTotal["UNKNOWN"].totalCalls
  //   );
  // };

  // Function to calculate average call duration for a specific source and call type

  // Function to calculate averages for all sources

  const calculateAverages = (grandTotal) => {
    grandTotal["PVT"] = calculateAverageForSource(grandTotal, "PVT");
    grandTotal["GST"] = calculateAverageForSource(grandTotal, "GST");
    grandTotal["UNKNOWN"] = calculateAverageForSource(grandTotal, "UNKNOWN");
  };

  // const processAggregatedData = (aggregatedData) => {
  //   let processedAggregatedData = {};
  //   let processedAggregatedDataArray = [];
  //   let grandTotal = {
  //     ["PVT"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       totalCalls: 0,
  //       totalDuration: 0,
  //       avgCallDuration: 0,
  //     },
  //     ["GST"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //     },
  //     ["UNKNOWN"]: {
  //       ["INBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       ["OUTBOUND"]: {
  //         totalCalls: 0,
  //         totalDuration: 0,
  //         avgCallDuration: 0,
  //       },
  //       totalCalls: 0,
  //       totalDuration: 0,
  //       avgCallDuration: 0,
  //     },
  //     totalCalls: 0,
  //     totalDuration: 0,
  //     avgCallDuration: 0,
  //   };
  //   aggregatedData.forEach((agData) => {
  //     let { summary, total } = agData;
  //     summary = JSON.parse(summary);
  //     if (total) {
  //       total = JSON.parse(total);
  //     } else {
  //       total = {};
  //     }
  //     const totalSourcesCalls = calculateTotal(total);

  //     addTotals(grandTotal, totalSourcesCalls);

  //     Object.keys(summary).forEach((Agent_Number) => {
  //       if (!processedAggregatedData[Agent_Number]) {
  //         processedAggregatedData[Agent_Number] = {
  //           Agent_Name: summary[Agent_Number].Agent_Name,
  //           ["PVT"]: {
  //             ["INBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             ["OUTBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             totalCalls: 0,
  //             totalDuration: 0,
  //             avgCallDuration: 0,
  //           },
  //           ["GST"]: {
  //             ["INBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             ["OUTBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             totalCalls: 0,
  //             totalDuration: 0,
  //             avgCallDuration: 0,
  //           },
  //           ["UNKNOWN"]: {
  //             ["INBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             ["OUTBOUND"]: {
  //               totalCalls: 0,
  //               totalDuration: 0,
  //               avgCallDuration: 0,
  //             },
  //             totalCalls: 0,
  //             totalDuration: 0,
  //             avgCallDuration: 0,
  //           },
  //           totalCalls: 0,
  //           totalDuration: 0,
  //           avgCallDuration: 0,
  //         };
  //       }
  //       addCallDetails(processedAggregatedData, Agent_Number, summary);
  //     });
  //   });
  //   calculateAverages(grandTotal);
  //   Object.keys(processedAggregatedData).forEach((Agent_Number) => {
  //     let Agent_Data = processedAggregatedData[Agent_Number];

  //     if (Agent_Data.totalCalls !== 0 || Agent_Data.totalDuration !== 0) {
  //       Agent_Data.avgCallDuration = Math.ceil(
  //         Agent_Data.totalDuration / Agent_Data.totalCalls
  //       );
  //       if (
  //         Agent_Data["GST"].totalCalls !== 0 ||
  //         Agent_Data["GST"].totalDuration !== 0
  //       ) {
  //         Agent_Data["GST"].avgCallDuration = Math.ceil(
  //           Agent_Data["GST"].totalDuration / Agent_Data["GST"].totalCalls
  //         );
  //       }
  //       if (
  //         Agent_Data["PVT"].totalCalls !== 0 ||
  //         Agent_Data["PVT"].totalDuration !== 0
  //       ) {
  //         Agent_Data["PVT"].avgCallDuration = Math.ceil(
  //           Agent_Data["PVT"].totalDuration / Agent_Data["PVT"].totalCalls
  //         );
  //       }
  //       processedAggregatedDataArray.push({
  //         Agent_Name,
  //         ...Agent_Data,
  //       });
  //     }
  //   });

  //   setDataSource(processedAggregatedDataArray);
  //   setFilteredData(aggregatedData);
  //   setTotal(grandTotal);
  //   return processedAggregatedData;
  // };

  // Function to parse summary and total data and calculate total calls and total duration
  const parseAndCalculateTotal = (summary, total) => {
    summary = JSON.parse(summary);
    total = total ? JSON.parse(total) : {};
    return calculateTotal(total);
  };

  // Function to initialize processed aggregated data for a specific agent
  const initializeProcessedAggregatedData = (Agent_Name, summary) => {
    const Agent_Number = summary[Agent_Name].Agent_Number;
    return {
      Agent_Number,
      ["PVT"]: {
        ["INBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        ["OUTBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      ["GST"]: {
        ["INBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        ["OUTBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      ["UNKNOWN"]: {
        ["INBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        ["OUTBOUND"]: { totalCalls: 0, totalDuration: 0, avgCallDuration: 0 },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      totalCalls: 0,
      totalDuration: 0,
      avgCallDuration: 0,
    };
  };

  // Function to process aggregated data
  const processAggregatedData = (aggregatedData) => {
    let processedAggregatedData = {};
    let processedAggregatedDataArray = [];
    let grandTotal = {
      ["PVT"]: {
        ["INBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        ["OUTBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      ["GST"]: {
        ["INBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        ["OUTBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      ["UNKNOWN"]: {
        ["INBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        ["OUTBOUND"]: {
          totalCalls: 0,
          totalDuration: 0,
          avgCallDuration: 0,
        },
        totalCalls: 0,
        totalDuration: 0,
        avgCallDuration: 0,
      },
      totalCalls: 0,
      totalDuration: 0,
      avgCallDuration: 0,
    };

    aggregatedData.forEach((agData) => {
      const { summary, total } = agData;
      const totalSourcesCalls = parseAndCalculateTotal(summary, total);
      addTotals(grandTotal, totalSourcesCalls);
      const parsedSummary = JSON.parse(summary);
      Object.keys(JSON.parse(summary)).forEach((Agent_Name) => {
        if (!processedAggregatedData[Agent_Name]) {
          processedAggregatedData[Agent_Name] =
            initializeProcessedAggregatedData(Agent_Name, parsedSummary);
        }
        addCallDetails(processedAggregatedData, Agent_Name, parsedSummary);
      });
    });

    calculateAverages(grandTotal);

    Object.keys(processedAggregatedData).forEach((Agent_Name) => {
      let Agent_Data = processedAggregatedData[Agent_Name];

      if (Agent_Data.totalCalls !== 0 || Agent_Data.totalDuration !== 0) {
        Agent_Data.avgCallDuration = Math.ceil(
          Agent_Data.totalDuration / Agent_Data.totalCalls
        );
        if (
          Agent_Data["GST"].totalCalls !== 0 ||
          Agent_Data["GST"].totalDuration !== 0
        ) {
          Agent_Data["GST"].avgCallDuration = Math.ceil(
            Agent_Data["GST"].totalDuration / Agent_Data["GST"].totalCalls
          );
        }
        if (
          Agent_Data["PVT"].totalCalls !== 0 ||
          Agent_Data["PVT"].totalDuration !== 0
        ) {
          Agent_Data["PVT"].avgCallDuration = Math.ceil(
            Agent_Data["PVT"].totalDuration / Agent_Data["PVT"].totalCalls
          );
        }
        processedAggregatedDataArray.push({ Agent_Name, ...Agent_Data });
      }
    });

    setDataSource(processedAggregatedDataArray);
    setFilteredData(aggregatedData);
    setTotal(grandTotal);

    return processedAggregatedData;
  };

  useEffect(() => {
    const fetchAirtelAggregatedData = async () => {
      setLoading(true);
      const data = await getAirtelAggregatedData();
      processAggregatedData(data.result);
      setDateWiseAggregatedData(data.result);
      setLoading(false);
    };
    if (dataSource.length === 0) fetchAirtelAggregatedData();
  }, []);

  return (
    <>
      <div>
        <Modal
          open={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1300}
        >
          <AirtelReportDownload
            setIsOpen={setIsModalOpen}
            dataSource={dataSource}
          />
        </Modal>
        <Modal
          open={isAgentReportOpen}
          onOk={handleAgentReportOk}
          onCancel={handleAgentReportCancel}
          okButtonProps={{
            style: { backgroundColor: "blue", color: "white" },
          }}
          width={1300}
        >
          <Agent_Report
            setIsOpen={setIsAgentReportOpen}
            dataSource={dataSource}
          />
        </Modal>
        <p className="text-center text-3xl my-10">
          Airtel Calling Details of{" "}
          <span className="text-3xl">
            <select
              value={selectedSource}
              style={{
                width: "fit-content",
                margin: "0",
                border: "none",
                outline: "none",
                appearance: "none",
                background: "transparent",
              }}
              onChange={handleChange}
            >
              {sourceOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </span>{" "}
          Source
        </p>
        <div className="mb-2 mx-auto grid grid-cols-2 gap-4 p-2">
          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">PVT Call Statistics</h2>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total PVT Calls
                      </td>
                      <td className="py-2 px-4">{total["PVT"].totalCalls}</td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total PVT Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total["PVT"].totalDuration)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="py-4 px-6 rounded-lg bg-white shadow-md">
            <h2 className="text-lg font-semibold mb-4">GST Call Statistics</h2>
            {loading ? (
              <Skeleton active />
            ) : (
              <div className="overflow-x-auto">
                <table className="w-full whitespace-nowrap">
                  <tbody>
                    <tr className="border-b border-gray-200 bg-gray-50">
                      <td className="py-2 px-4 font-semibold">
                        Total GST Calls
                      </td>
                      <td className="py-2 px-4">{total["GST"].totalCalls}</td>
                    </tr>
                    <tr className="border-b border-gray-200 bg-gray-100">
                      <td className="py-2 px-4 font-semibold">
                        Total GST Duration
                      </td>
                      <td className="py-2 px-4">
                        {secondsToHMS(total["GST"].totalDuration)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>

        <div>
          <div className="flex flex-row justify-between mt-5 mb-5">
            <div className="ml-10">
              <span className="mx-2">From</span>
              <DatePicker
                onChange={(date, dateString) => setStartDate(dateString)}
                format={"DD/MM/YYYY"}
                placeholder="Start date"
              />
              <span className="mx-2">to</span>
              <DatePicker
                onChange={(date, dateString) => {
                  setEndDate(dateString);
                }}
                format={"DD/MM/YYYY"}
                placeholder="End date"
              />
              <button
                className="px-3 py-1 rounded-md bg-orange-500 ml-2 text-black font-semibold"
                onClick={() => rangeWiseFiltering(startDate, endDate)}
              >
                Search
              </button>
              <button
                className="px-3 py-1 rounded-md bg-orange-500 ml-2 text-black font-semibold"
                onClick={() => clearFilter()}
              >
                Clear Filter
              </button>
            </div>

            <div className="ml-1">
              <button
                onClick={viewAgentReport}
                className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
              >
                View Agent Report
              </button>
              <button
                onClick={viewCSV}
                className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
              >
                View Report
              </button>

              {/* <button
                className=" border-2 border-orange-500 mx-2 rounded-md text-orange-500 p-1 transition duration-150 hover:scale-105 cursor-pointer"
                onClick={handleDownload}
              >
                Download CSV
              </button> */}
            </div>

            {/* <CSVLink
              id="csv-link"
              // data={dataSource}
              data={csvData}
              filename={`AirtelReporData_${moment().format(
                "YYYYMMDDHHmmss"
              )}.csv`}
            /> */}
          </div>
          {!loading ? (
            <>
              <AirtelGraphContainer
                filteredData={filteredData}
                graphType={graphType}
              />
              <EmployeeGraph filteredData={filteredData} />

              <Table id="report" columns={columns} dataSource={dataSource} />
            </>
          ) : (
            <div className="flex justify-center items-center min-h-screen min-w-screen">
              <Spin />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Agent_Customer_Talk_Time_Report;
