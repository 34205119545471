import React, { useRef } from "react";
import moment from "moment";
import html2canvas from "html2canvas";
import { DownloadOutlined, CopyOutlined } from "@ant-design/icons";
import { handleCopyImage } from "../../../helper";

const DialerAllTable = ({ totalMissedCalls, dialerAllData, incomingType }) => {
  const tableRef = useRef(null);

  function formatTime(second) {
    const seconds = Math.floor(second);

    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    // const formattedHours = String(hours).padStart(2, "0");
    const formattedHours = hours;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const captureAndDownload = () => {
    const element = document.getElementById("capture");
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `incoming-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  let totalAns = 0;
  let totalCDuration = 0;
  let avg = 0;
  return (
    <div className="sm:rounded-lg my-5 rounded-md w-[800px] flex items-center flex-col mx-auto">
      <div className="flex mr-[42rem] space-x-4">
        <button onClick={captureAndDownload}>
          <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button onClick={() => handleCopyImage(tableRef)} className="">
          <CopyOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
      </div>
      <table
        className="table-auto  w-11/12 mx-auto  text-sm font-bold  rounded-md"
        id="capture"
        ref={tableRef}
      >
        <thead className="text-black uppercase font-bold  bg-[#FEFF00]">
          <tr>
            <th className="border border-black pb-2 font-bold text-center">
              Sr. No
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              Name
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              Answered Calls
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              Total Call Duration
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              AVG. MINS
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(dialerAllData).map(([name, agentData], index) => {
            const totalAnsweredCalls = agentData.total_answered_calls || 0;
            const totalCallDuration = agentData.total_call_duration || 0;
            const averageMinPerAnsweredCall =
              agentData.average_min_ans_call || 0;
            totalAns += totalAnsweredCalls;
            totalCDuration += totalCallDuration;
            avg += averageMinPerAnsweredCall;
            return (
              <tr key={name}>
                <td className="border pb-2 border-black text-center">
                  {index + 1}
                </td>
                <td className="border pb-2 border-black text-center">
                  {name?.toUpperCase()}
                </td>

                <td className="border pb-2 border-black text-center">
                  {totalAnsweredCalls}
                </td>
                <td className="border pb-2 border-black text-center">
                  {formatTime(totalCallDuration)}
                </td>
                <td className="border pb-2 border-black text-center">
                  {formatTime(averageMinPerAnsweredCall)}
                </td>
              </tr>
            );
          })}
          <tr className="bg-[#FEFF00]">
            <td
              colSpan="2"
              className="border pb-2 border-black font-bold text-center uppercase"
            >
              Total
            </td>

            <td className="border pb-2 border-black font-bold text-center">
              {totalAns}
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              {formatTime(totalCDuration)}
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              {formatTime(Math.round(totalCDuration / totalAns))}
            </td>
            <td colSpan="4"></td>
          </tr>
          <tr className="bg-[#F5AE82]">
            <td
              colSpan="2"
              className="border pb-2 border-black font-bold text-center uppercase"
            >
              Missed Call
            </td>

            <td className="border pb-2 border-black font-bold text-center">
              {totalMissedCalls}
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              -
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              -
            </td>

            <td colSpan="4"></td>
          </tr>
          <tr className="bg-[#9BC0E7]">
            <td
              colSpan="2"
              className="border pb-2 border-black font-bold text-center uppercase"
            >
              Grand Total
            </td>

            <td className="border pb-2 border-black font-bold text-center">
              {totalAns + (totalMissedCalls || 0)}
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              -
            </td>
            <td className="border pb-2 border-black font-bold text-center">
              -
            </td>

            <td colSpan="4"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DialerAllTable;
