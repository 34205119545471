import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const TataTeleIncome = () => {
  const navigate = useNavigate();
  const role = localStorage.getItem("role");
  useEffect(() => {
    if (!localStorage.getItem("authToken")) {
      navigate("/login");
    }
  }, []);

  let allowedSalesUsers = ["tarunpatel@egniol.co.in"];
  return (
    <div>
      {role === "sales" &&
        allowedSalesUsers.includes(localStorage.getItem("email")) && (
          <iframe
            title="BARODA INCOME TAX"
            width="600"
            height="486"
            src="https://app.powerbi.com/view?r=eyJrIjoiM2ZjZWM0NGQtNzg0ZS00NTVhLWJhN2ItZDJmZjE5ZjQ2ZjAyIiwidCI6IjhkYjJiZjNmLTM2ZTgtNDg5Ny1iOWNlLTFjMTcyNGY3NjQyYSJ9"
            frameborder="0"
            className="w-screen"
            allowFullScreen="true"
            style={{ height: "90vh" }}
          ></iframe>
        )}
      {role === "admin" && (
        <iframe
          title="BARODA INCOME TAX"
          width="600"
          height="486"
          src="https://app.powerbi.com/view?r=eyJrIjoiM2ZjZWM0NGQtNzg0ZS00NTVhLWJhN2ItZDJmZjE5ZjQ2ZjAyIiwidCI6IjhkYjJiZjNmLTM2ZTgtNDg5Ny1iOWNlLTFjMTcyNGY3NjQyYSJ9"
          frameborder="0"
          className="w-screen"
          allowFullScreen="true"
          style={{ height: "90vh" }}
        ></iframe>
      )}
    </div>
  );
};

export default TataTeleIncome;
