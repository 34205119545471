import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import moment from "moment";
import {
  DownloadOutlined,
  CopyOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import {
  formatDateRange,
  handleCopyImage,
  handleWhatsappSend,
} from "../../../helper";
import { useParams } from "react-router-dom";

const ReportTable = ({ data, startDate, endDate }) => {
  const tableRef = useRef(null);
  const { start, end } = useParams();

  function formatTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const captureAndDownload = () => {
    const element = document.getElementById("captureOpenPhone"); // ID of the element to capture
    html2canvas(element).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = `OpenPhone-${moment().format("YYYY-MM-DD HH:mm:ss")}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  let totalAnsweredCalls = 0;
  let totalCallDuration = 0;
  let totalAverageMinPerAnsweredCall = 0;

  useEffect(() => {
    if (start !== undefined && end !== undefined) {
      setTimeout(() => {
        handleWhatsappSend(tableRef);
      }, 10000);
    }
  }, [start && end]);

  return (
    <div className="overflow-x-auto relative flex flex-col items-center  my-5 w-[800px]">
      <div className="flex pb-1 mr-[44rem]  space-x-4">
        <button onClick={captureAndDownload}>
          <DownloadOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button onClick={() => handleCopyImage(tableRef)} className="">
          <CopyOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
        <button
          onClick={async () =>
            await handleWhatsappSend(
              tableRef,
              `*CANADA REPORT ${await formatDateRange(startDate, endDate)}*`
            )
          }
          className=""
        >
          <WhatsAppOutlined style={{ fontSize: "20px", color: "#08c" }} />
        </button>
      </div>
      <table
        className="table-auto w-full text-sm text-left shadow-md "
        id="captureOpenPhone"
        ref={tableRef}
      >
        <thead className="text-black  uppercase bg-[#FEFF00]">
          <tr>
            <th className="pb-2 border border-black text-black text-center">
              Sr. No
            </th>
            <th className="pb-2 border border-black text-black text-center">
              Name
            </th>
            <th className="pb-2 border border-black text-black text-center">
              {" "}
              Answered Calls
            </th>
            <th className="pb-2 border border-black text-black text-center">
              {" "}
              Call Duration
            </th>
            <th className="pb-2 border border-black text-black text-center">
              Average Duration
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Object.entries(data).map(([name], index) => {
            totalAnsweredCalls += data[name].totalCalls;
            totalCallDuration += data[name].totalAnswerDuration;
            totalAverageMinPerAnsweredCall += Math.round(
              data[name].averageDuration
            );
            return (
              <React.Fragment key={name}>
                <tr key={index}>
                  <td className="border border-black pb-2 text-black text-center">
                    {index + 1}
                  </td>
                  <td className="border border-black pb-2 text-black text-center">
                    {name}
                  </td>
                  <td className="border border-black pb-2 text-black text-center">
                    {data[name].totalCalls}
                  </td>

                  <td className="border border-black pb-2 text-black text-center">
                    {formatTime(data[name].totalAnswerDuration)}
                  </td>
                  <td className="border border-black pb-2 text-black text-center">
                    {formatTime(Math.round(data[name].averageDuration))}
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
          <tr className="">
            <td
              colSpan="2"
              className="pb-2 border border-black text-black font-semibold text-center"
            >
              Total
            </td>

            <td className="pb-2 border border-black text-black font-semibold text-center">
              {totalAnsweredCalls}
            </td>
            <td className="pb-2 border border-black text-black font-semibold text-center">
              {formatTime(totalCallDuration)}
            </td>
            <td className="pb-2 border border-black text-black font-semibold text-center">
              {formatTime(totalAverageMinPerAnsweredCall)}
            </td>
            <td colSpan="4"></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportTable;
