import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

const HourWiseApiReplyGraph = ({ chartData }) => {
  return (
    <ResponsiveContainer width={"100%"} height={"100%"}>
      <LineChart width={500} height={500} data={chartData}>
        <XAxis dataKey="hour" />
        <YAxis />
        <Legend />
        <Tooltip />
        <CartesianGrid strokeDasharray="0 0" />
        <Line type="count" dataKey="count" stroke="#36A2EB" fill="#36A2EB" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HourWiseApiReplyGraph;
