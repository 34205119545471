import client from "./client";

export const getDataDateWise = async (startDate, endDate) => {
  try {
    const { data } = await client.get(
      `/openPhoneCanada/getDataDateWise?startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};

export const getAgentStats = async (startDate, endDate) => {
  try {
    const { data } = await client.get(
      `/openPhoneCanada/agent-stats?startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  } catch (error) {
    const { response } = error;
    if (response?.data) {
      return response.data;
    }
    return { error: error.message || error };
  }
};
