import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  Tooltip,
} from "recharts";

import Select from "react-select";

const EmployeeGraph = ({ filteredData, CallStatus }) => {
  const [data, setData] = useState([]);
  const [agents, setAgents] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("");

  const employeeWiseData = () => {
    const processDataArray = [];
    filteredData.forEach((data) => {
      const summary = JSON.parse(data.summary);
      if (CallStatus === "ALL") {
        const pvtAnsCalls =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["PVT"].totalCalls
            : 0;
        const pvtMisCalls =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["PVT"].totalCalls
            : 0;
        const gstAnsCalls =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["GST"].totalCalls
            : 0;
        const gstMisCalls =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["GST"].totalCalls
            : 0;
        const unAnsCalls =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["UNKNOWN"].totalCalls
            : 0;
        const unMisCalls =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["UNKNOWN"].totalCalls
            : 0;
        let pvtAnsInt =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["PVT"].totalInterested
            : 0;
        let gstAnsInt =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["GST"].totalInterested
            : 0;
        let unAnsInt =
          summary[selectedAgent] && summary[selectedAgent]["ANSWERED"]
            ? summary[selectedAgent]["ANSWERED"]["UNKNOWN"].totalInterested
            : 0;
        let pvtMisInt =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["PVT"].totalInterested
            : 0;
        let gstMisInt =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["GST"].totalInterested
            : 0;
        let unMisInt =
          summary[selectedAgent] && summary[selectedAgent]["MISSED"]
            ? summary[selectedAgent]["MISSED"]["UNKNOWN"].totalInterested
            : 0;
        const totalInterested =
          pvtAnsInt + pvtMisInt + gstAnsInt + gstMisInt + unAnsInt + unMisInt;
        processDataArray.push({
          name: data.date,
          pvtTotalCalls: pvtAnsCalls + pvtMisCalls,
          gstTotalCalls: gstAnsCalls + gstMisCalls,
          unknownTotalCalls: unAnsCalls + unMisCalls,
          totalInterested: totalInterested,
        });
      } else {
        let pvtInt =
          summary[selectedAgent] && summary[selectedAgent][CallStatus]
            ? summary[selectedAgent][CallStatus]["PVT"].totalInterested
            : 0;
        let gstInt =
          summary[selectedAgent] && summary[selectedAgent][CallStatus]
            ? summary[selectedAgent][CallStatus]["GST"].totalInterested
            : 0;
        let unInt =
          summary[selectedAgent] && summary[selectedAgent][CallStatus]
            ? summary[selectedAgent][CallStatus]["UNKNOWN"].totalInterested
            : 0;
        processDataArray.push({
          name: data.date,
          pvtTotalCalls:
            summary[selectedAgent] && summary[selectedAgent][CallStatus]
              ? summary[selectedAgent][CallStatus]["PVT"].totalCalls
              : 0,
          gstTotalCalls:
            summary[selectedAgent] && summary[selectedAgent][CallStatus]
              ? summary[selectedAgent][CallStatus]["GST"].totalCalls
              : 0,
          unknownTotalCalls:
            summary[selectedAgent] && summary[selectedAgent][CallStatus]
              ? summary[selectedAgent][CallStatus]["UNKNOWN"].totalCalls
              : 0,
          totalInterested: pvtInt + unInt + gstInt,
        });
      }
    });
    const sortedData = processDataArray.sort((a, b) => {
      const dateA = new Date(a.name.split("/").reverse().join("/"));
      const dateB = new Date(b.name.split("/").reverse().join("/"));
      return dateA - dateB;
    });
    setData(sortedData);
  };

  const handleAgentChange = (selectedOption) => {
    setSelectedAgent(selectedOption.value);
  };
  const getAllAgents = () => {
    // Define a Set to store unique agents
    let allAgentsSet = new Set();

    filteredData.forEach((data) => {
      const summary = JSON.parse(data.summary);
      let agents = Object.keys(summary);
      agents.forEach((agent) => {
        if (!allAgentsSet.has(agent)) {
          allAgentsSet.add(agent);
        }
      });
    });

    // Convert the Set to an array
    let allAgentsArray = Array.from(allAgentsSet);
    allAgentsArray = allAgentsArray.map((agent) => ({
      value: agent,
      label: agent,
    }));
    setAgents(allAgentsArray);
  };

  useEffect(() => {
    if (selectedAgent) {
      employeeWiseData();
    }
  }, [selectedAgent]);

  useEffect(() => {
    getAllAgents();
  }, [filteredData, CallStatus]);

  return (
    <div className="border-2 p-4 m-4 border-dashed rounded-lg">
      <p className="text-center text-4xl">Employee Graph</p>
      <div className="ml-20">
        <Select
          value={{ value: selectedAgent, label: selectedAgent }}
          isSearchable
          onChange={(selectedValue) => handleAgentChange(selectedValue)}
          options={agents}
          placeholder="Select an Agent ....."
        />
      </div>
      <div
        style={{
          height: "30rem",
        }}
        class="p-4 my-4 rounded-3xl lg:col-span-3 flex items-center justify-center "
      >
        <ResponsiveContainer width={"100%"} height={"100%"}>
          <LineChart width={500} height={500} data={data}>
            <XAxis dataKey="name" />
            <YAxis />
            <Legend />
            <Tooltip />
            <CartesianGrid strokeDasharray="5 5" />
            <Line
              type="monotone"
              dataKey="pvtTotalCalls"
              stroke="#E178C5"
              fill="#E178C5"
            />
            <Line
              type="monotone"
              dataKey="gstTotalCalls"
              stroke="#7743DB"
              fill="#7743DB"
            />
            <Line
              type="monotone"
              dataKey="unknownTotalCalls"
              stroke="#36A2EB"
              fill="#36A2EB"
            />
            <Line
              type="monotone"
              dataKey="totalInterested"
              stroke="green"
              fill="green"
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default EmployeeGraph;
