import React, { useState, useEffect } from "react";
import { getAgentsReport } from "../../api/dialer";
import { CSVLink } from "react-csv";
import { DatePicker, Select } from "antd";

const { RangePicker } = DatePicker;
const { Option } = Select;

const dialerReportHeaders = [
  {
    key: "Agent_Name",
    label: "NAME",
  },
  {
    label: "OUTGOING",
    key: "outboundTalkTime",
  },
  {
    label: "INGOING",
    key: "inboundTalkTime",
  },
  {
    label: "ANSWERED",
    key: "answered",
  },
  {
    label: "MISSED",
    key: "missed",
  },
  {
    label: "TARGET",
    key: "target",
  },
  {
    label: "ACH.TOTAL",
    key: "totalTalkTime",
  },
];

const DialerAgentReport = () => {
  const [dialerReport, setDialerReport] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [agentInput, setAgentInput] = useState("");
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaigns, setCampaigns] = useState([]);
  const campaignOptions = [
    { value: "ALL", label: "ALL" },
    { value: "INCOME TAX", label: "INCOME TAX" },
    { value: "1800 INCOMING", label: "1800 INCOMING" },
    { value: "TEAM A", label: "TEAM A" },
    { value: "TEAM B", label: "TEAM B" },
    { value: "CHENNAI INCOME TAX", label: "CHENNAI INCOME TAX" },
    { value: "CHENNAI TEAM", label: "CHENNAI TEAM" },
  ];

  const combineCampaignData = (agent, campaign) => {
    const campaigns = Object.keys(agent).filter((key) =>
      [
        "INCOME TAX",
        "1800 INCOMING",
        "TEAM A",
        "TEAM B",
        "CHENNAI INCOME TAX",
        "CHENNAI TEAM",
      ].includes(key)
    );

    const campaignData =
      campaign === "ALL"
        ? campaigns.map(
            (camp) =>
              agent[camp] || {
                ANSWERED: 0,
                MISSED: 0,
                OUTBOUND: "00:00:00",
                INBOUND: "00:00:00",
                TOTAL: "00:00:00",
              }
          )
        : [
            agent[campaign] || {
              ANSWERED: 0,
              MISSED: 0,
              OUTBOUND: "00:00:00",
              INBOUND: "00:00:00",
              TOTAL: "00:00:00",
            },
          ];

    const totalData = campaignData.reduce(
      (acc, curr) => {
        const formatTime = (time) => {
          const parts = time.split(":");
          return +parts[0] * 3600 + +parts[1] * 60 + +parts[2];
        };

        acc.OUTBOUND += formatTime(curr.OUTBOUND);
        acc.INBOUND += formatTime(curr.INBOUND);
        acc.TOTAL += formatTime(curr.TOTAL);
        acc.ANSWERED += curr.ANSWERED;
        acc.MISSED += curr.MISSED;

        return acc;
      },
      { OUTBOUND: 0, INBOUND: 0, TOTAL: 0, ANSWERED: 0, MISSED: 0 }
    );

    const formatToTime = (totalSeconds) => {
      const hours = Math.floor(totalSeconds / 3600)
        .toString()
        .padStart(2, "0");
      const minutes = Math.floor((totalSeconds % 3600) / 60)
        .toString()
        .padStart(2, "0");
      const seconds = (totalSeconds % 60).toString().padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    };

    return {
      OUTBOUND: formatToTime(totalData.OUTBOUND),
      INBOUND: formatToTime(totalData.INBOUND),
      TOTAL: formatToTime(totalData.TOTAL),
      ANSWERED: totalData.ANSWERED,
      MISSED: totalData.MISSED,
    };
  };

  const formatedResults = () => {
    const formatedReport = filteredResults.map((agent) => {
      const combinedData = combineCampaignData(agent, selectedCampaign);
      return {
        Agent_Name: agent.Agent_Name,
        outboundTalkTime: combinedData.OUTBOUND,
        inboundTalkTime: combinedData.INBOUND,
        answered: combinedData.ANSWERED,
        missed: combinedData.MISSED,
        target: agent.target,
        totalTalkTime: combinedData.TOTAL,
      };
    });
    return formatedReport || [];
  };

  const getDialerReport = async (startDate, endDate) => {
    const { agentReport, campaigns } = await getAgentsReport({
      startDate: startDate ? startDate.format("YYYY-MM-DD") : "",
      endDate: endDate ? endDate.format("YYYY-MM-DD") : "",
    });

    const filteredReport = agentReport.sort(
      (a, b) => b.totalTalkTime - a.totalTalkTime
    );
    setCampaigns(campaigns);
    setDialerReport(filteredReport);
    filterResults(filteredReport, agentInput, selectedCampaign);
  };

  const filterResults = (report, agentInput, campaign) => {
    if (campaign) {
      const agents = agentInput
        .split(/\r?\n/)
        .map((name) => name.trim().toUpperCase())
        .filter((name) => name.length > 0);
      const filtered = report
        .filter(
          (agent) =>
            (agents.length === 0 || agents.includes(agent.Agent_Name)) &&
            (campaign === "ALL" || agent[campaign])
        )
        .sort((a, b) => {
          const totalA = combineCampaignData(a, campaign).TOTAL;
          const totalB = combineCampaignData(b, campaign).TOTAL;
          return totalB.localeCompare(totalA);
        });
      setFilteredResults(filtered);
    } else {
      const agents = agentInput
        .split(/\r?\n/)
        .map((name) => name.trim().toUpperCase())
        .filter((name) => name.length > 0);
      const filtered = report
        .filter(
          (agent) => agents.length === 0 || agents.includes(agent.Agent_Name)
        )
        .sort(
          (a, b) =>
            (b["TOTAL"] ? b["TOTAL"].TOTAL : 0) -
            (a["TOTAL"] ? a["TOTAL"].TOTAL : 0)
        );
      setFilteredResults(filtered);
    }
  };

  const handleDateChange = (dates) => {
    setDateRange(dates);
    if (dates) {
      getDialerReport(dates[0], dates[1]);
    }
  };

  const handleAgentInputChange = (e) => {
    const value = e.target.value;
    setAgentInput(value);
    filterResults(dialerReport, value, selectedCampaign);
  };

  const handleCampaignChange = (campaign) => {
    setSelectedCampaign(campaign);
    filterResults(dialerReport, agentInput, campaign);
  };

  const handleDownload = () => {
    document.getElementById("download-csv").click();
  };

  return (
    <div>
      <div className="flex m-4">
        <RangePicker
          onChange={handleDateChange}
          format="YYYY-MM-DD"
          className="mr-2"
        />
        <textarea
          style={{ width: "200px" }}
          className="border-2 rounded-md"
          placeholder="Paste agent names here, separated by new lines"
          onChange={handleAgentInputChange}
          value={agentInput}
        />
        <Select
          style={{ width: "200px" }}
          placeholder="Select Campaign"
          onChange={handleCampaignChange}
        >
          {campaignOptions.map((campaign) => (
            <Option key={campaign.value} value={campaign.value}>
              {campaign.label}
            </Option>
          ))}
        </Select>
        <button
          className="text-blue-600  border-2 w-fit border-blue-600 rounded-md"
          onClick={handleDownload}
        >
          Download
        </button>
      </div>

      <CSVLink
        id="download-csv"
        data={formatedResults()}
        headers={dialerReportHeaders}
        filename={`dialerAgentTalkTimeReport.csv`}
      />
      <table
        className="table-auto w-[60%] mb-8 mx-auto text-sm font-bold rounded-md"
        id="capture"
      >
        <thead className="text-black uppercase font-bold bg-[#FEFF00]">
          <tr>
            <th className="border border-black pb-2 font-bold text-center">
              AGENT
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              OUTGOING
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              INGOING
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              ANSWERED
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              MISSED
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              TARGET
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              TOTAL
            </th>
            <th className="border border-black pb-2 font-bold text-center">
              %
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredResults?.map((agentInfo) => {
            const combinedData = combineCampaignData(
              agentInfo,
              selectedCampaign
            );
            return (
              <tr key={agentInfo.Agent_Name}>
                <td className="border w-10 bg-orange-300 pb-2 border-black text-center">
                  {agentInfo.Agent_Name}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {combinedData.OUTBOUND}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {combinedData.INBOUND}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {combinedData.ANSWERED}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {combinedData.MISSED}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {agentInfo.target}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  {combinedData.TOTAL}
                </td>
                <td className="border w-10 pb-2 border-black text-center">
                  --
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DialerAgentReport;
