import { Spin } from "antd";
import React from "react";

const Table = ({ data, loading, columnHeadings, header, footer }) => {

  console.log(data, "Data-Data");

  // Calculate totals
  const initialTotals = { adi: 0, chn: 0, total: 0, rowTotal: 0 };
  const totals = data.reduce((acc, item) => {
    if (item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA") {
      acc.adi += item.leads || 0;
      acc.chn += item.remarks || 0;
      acc.rowTotal += (item.leads || 0) + (item.remarks || 0);
    }
    return acc;
  }, initialTotals);

  // Add a new field for row totals
  const dataWithRowTotals = data.map(item => ({
    ...item,
    rowTotal: (item?.leads || 0) + (item?.remarks || 0)
  }));

  // Reorder the data according to your desired sequence for slabs, keeping other items as is
  const slabOrder = [
    "Slab: Rs. 100 Cr. to 500 Cr.",
    "Slab: Rs. 25 Cr. to 100 Cr.",
    "Slab: Rs. 5 Cr. to 25 Cr.",
    "Slab: Rs. 1.5 Cr. to 5 Cr.",
    "Slab: Rs. 40 lakhs to 1.5 Cr.",
    "Slab: Rs. 0 to 40 lakhs"
  ];

  const orderedData = [
    ...dataWithRowTotals.filter(item => !slabOrder.includes(item?.source)),  // Include PVT, GT, UDY, and any other sources
    ...slabOrder.map(slab => dataWithRowTotals.find(item => item?.source === slab))
  ];

  return (
    <Spin spinning={loading}>
      <div className="overflow-x-auto w-[600px] border rounded-sm">
        <table className="table-auto min-w-full text-center">
          <thead className="bg-gray-50 text-center">
            <tr>
              <th
                colSpan={5}  // Increased by 1 for the new column
                className={`text-lg border-2 border-black bg-[#91D053] font-semibold text-center`}
              >
                {header}
              </th>
            </tr>
            <tr className="text-center">
              {columnHeadings.map((heading, index) => (
                <th
                  key={index}
                  scope="col"
                  className={`border-2 border-black bg-[#91D053] uppercase tracking-wider`}
                >
                  {heading}
                </th>
              ))}
              <th
                className={`border-2 border-black bg-[#91D053] uppercase tracking-wider`}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {/* {dataWithRowTotals.map((item, index) => ( */}
            {orderedData.map((item, index) => (
              <tr
                key={index}
                className={index % 2 === 0 ? "bg-gray-50" : "bg-white"}
              >
                <td
                  className={`text-gray-900 border-2 border-black font-semibold
                    ${item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA"
                      ? "bg-[#B1C6E9]"
                      : "text-white bg-black"}
                  `}
                >
                  {columnHeadings[1] !== "Type"
                    ? index + 1
                    : index < 2
                      ? index + 1
                      : "*"}
                </td>
                <td
                  className={`text-gray-900 border-2 border-black font-semibold
                    ${item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA"
                      ? "bg-[#B1C6E9]"
                      : "text-white bg-black"}
                  `}
                >
                  {item?.source}
                </td>
                <td
                  className={`text-sm text-gray-900 border-2 border-black font-semibold
                    ${item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA"
                      ? "bg-[#B1C6E9]"
                      : "text-white bg-black"}
                  `}
                >
                  {item?.leads}
                </td>
                <td
                  className={`text-sm text-gray-900 border-2 border-black font-semibold
                    ${item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA"
                      ? "bg-[#B1C6E9]"
                      : "text-white bg-black"}
                  `}
                >
                  {item?.remarks}
                </td>
                <td
                  className={`text-sm text-gray-900 border-2 border-black font-semibold
                    ${item?.source === "PVT" || item?.source === "GT" || item?.source === "UDY" || item?.source === "NA"
                      ? "bg-[#B1C6E9]"
                      : "text-white bg-black"}
                  `}
                >
                  {item?.rowTotal}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr className="font-bold bg-[#e0e0e0]">
              <td className="border-2 border-black px-1" colSpan="2">GRAND TOTAL</td>
              <td className="border-2 border-black px-1">{totals.adi}</td>
              <td className="border-2 border-black px-1">{totals.chn}</td>
              <td className="border-2 border-black px-1">{totals.rowTotal}</td>
            </tr>
          </tfoot>
        </table>
      </div>
    </Spin>
  );
};

export default Table;
